import _ from 'lodash';
import MakeEventBus from '../MakeEventBus';
import { LoaderPlugin } from 'vue-google-login';
import Vue from 'vue';

const EventBus = MakeEventBus('widgets');
export default ctx => {
  return action => {
    return new Promise(async (resolve, reject) => {
      //////////////////nko

      let Value = {};
      let backendnameHashtagsValues = _.cloneDeep(ctx.store.state.hashtags.Backendname);


      //////////////////google
      if (action.options.selectedSystem == 'google' || true) {
        let type1 = 'googleoauth';
        let { Key1 } = await getKey(ctx, type1);
        //console.log('keys', Key1);
        if (typeof Key1 == 'undefined') {
          return 'component-avoid';
        }

        var auth2;
        var googleUser;

        var signinChanged = function (val) {
          console.log('Signin state changed to ', val);
        };

        var userChanged = function (user) {
          //console.log('User now: ', user);
          googleUser = user;
          var profile = googleUser.getBasicProfile();
          //console.log('ID: ' + profile.getId());
          //console.log('Name: ' + profile.getName());
          //console.log('Image URL: ' + profile.getImageUrl());
          //console.log('Email: ' + profile.getEmail());
          var id_token = googleUser.getAuthResponse().id_token;
          //console.log('id_token: ' + id_token);
          let { exp } = getAuthLink(ctx, id_token);
        };


        Vue.use(LoaderPlugin, {
          client_id: Key1,
        });


        Vue.GoogleAuth.then(auth2 => {
          //console.log(auth2.isSignedIn.get());
          auth2.signIn();

          //console.log(auth2.currentUser.get());
          auth2.isSignedIn.listen(signinChanged);

          auth2.currentUser.listen(userChanged);


        });


      }
      //////////////////google end


    });
  };
}


async function getKey(ctx, type) {
  let res = {};
  try {

    const { objid, appid, screenid } = ctx.query;
    let paramsForSign = {
      appId: appid,
      type: type,
    };

    res = await ctx.$axios.$post(`/auth/applicationoauth`, paramsForSign);
    //console.log(res);
    if (res.Key1) {
      return res;
    } else {
      return false;
    }

  } catch (e) {
    console.log({ title: 'Error google acc3', message: e });
    return false;
  }
  //console.log('NKO', NKO);
  return res;

}

async function getAuthLink(ctx, token) {
  let res = {};
  try {

    const { objid, appid, screenid } = ctx.query;
    let paramsForSign = {
      appId: appid,
      // objectId: objid,
      id_token: token,
    };

    // res = await ctx.$axios.$post(`/auth/goauth`, paramsForSign);
    res = await ctx.$axios.$post(`/auth/glink`, paramsForSign);
    console.log(res);
    if (res.exp) {
      return res;
    } else {
      console.log('Not executed1');
      // ctx.app.$ons.notification.alert('Not executed', {timeout: 1000});
      // ctx.app.$q.dialog({ title: 'Attention', message: 'Not enough data for sign' });
      return false;
    }

  } catch (e) {
    console.log({ title: 'Error google acc2', message: e });
    return false;
  }
  //console.log('NKO', NKO);
  return res;

}



