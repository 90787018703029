import _ from 'lodash';

export default ({ $axios, store, env, app }) => {
  const { protocol, hostname } = location;
  const prepareHostname = hostname.split('.')
    .map((item, ind) => item = ind == 0 ? `${item}-admin` : item)
    .join('.');
  const isDev = (env.NODE_ENV === 'development');
  const BASE_API_URL = isDev ? env.BASE_API_URL : `${protocol}//${prepareHostname}`;
  const API_URL = `${BASE_API_URL}/api/v8`;

  const isUrlNotLogin = url => _.includes([
    '/mu/authorize',
    '/mu/refresh',
    '/auth/info/application',
    '/auth/info/object',
  ], url);

  $axios.onRequest(async config => {
    config.headers['Cache-Control'] = 'no-cache, no-store, must-revalidate'
    if (!config.isExternal) {
      const token = store.getters.accessToken;
      config.url = config.url.replace(API_URL, '');
      if (!isUrlNotLogin(config.url) && token) config.headers['Authorization'] = `Bearer ${token}`;
      config.url = `${API_URL}/${config.url.replace(/^\//, '')}`;
    }
    return config;
    // if (!isUrlNotLogin(config.url) && !token) return await store.dispatch('getAccess');
  });

  $axios.onResponse(response => {
    if (response.status === 200) {
      store.commit('update', { key: 'lastUpdateDate', data: Date.now() });
    }
  });

  $axios.onError(async _error => {
    const { response, config } = _error;
    const originalRequest = config;
    const code = parseInt(response && response.status);

    if (code === 401 && !originalRequest.__isRetryRequest) {
      try {
        const auth = await store.dispatch('refreshToken');
        if (!auth) {
          throw new Error();
        }
        originalRequest.__isRetryRequest = true;
        originalRequest.headers.Authorization = `Bearer ${auth.access_token}`;
        return $axios(originalRequest);
      } catch (e) {
        const { isStopReq } = store.state.application;
        if (isStopReq) return Promise.reject(_error);
        store.commit('application/setStateByKey', { key: 'isStopReq', payload: true });
        app.$q.dialog({ title: 'Access denied', message: 'Token expired' })
          .onOk(() => {
            store.commit('application/setStateByKey', { key: 'isStopReq', payload: false });
          })
        await store.dispatch('logout');
      }
    }

    if (code === 403) {
      const { isStopReq } = store.state.application;
      if (isStopReq) return Promise.reject(_error);
      store.commit('application/setStateByKey', { key: 'isStopReq', payload: true });

      const { errors } = response.data || {};
      let { title, detail } = (errors || [])[0] || {};
      if ( store.state.application.authType ) {
        app.$q.dialog({ title, message: detail })
          .onOk(() => {
            store.commit('application/setStateByKey', { key: 'isStopReq', payload: false });
          })
      } else {
        store.commit('application/setStateByKey', { key: 'isStopReq', payload: false });
      }
      await store.dispatch('logout');
    }

    return Promise.reject(_error);
  });
}
