import { collectDependencies } from '../screen';

export default ctx => action =>
  new Promise( (resolve, reject) => {
    const { hashTags } = collectDependencies(ctx.store.state.screen.originalData, true);

    ctx.store.dispatch('screen/getValueHashtagsFromAPI', { hashTags })
      .then(result => result instanceof Error ? reject(result) : resolve())

  });
