export default async ({ store, route, error, redirect }) => {
  if (route.name === '404') return;

  const { appid: applicationId, objid: objectId } = route.query;

  if (route.path == '/') return redirect(`/${applicationId}`, { ...route.query });

  // Check ApplicationID
  if (!applicationId)
    return error({
      statusCode: 500, message: 'Can\'t launch application. Application ID is not defined'
    });

  // Check Application Info
  if (!store.state.application.id)
    return error({
      statusCode: 500,
      message: 'Can\'t launch application. Wrong Application ID'
    });

  // Check Object Info
  if (route.name !== 'auth-signin' && route.name !== 'auth-signup') {
    if ( !store.state.object.id || store.state.object.errors ) {
      const { code } = (store.state.object.errors || [])[0] || {};
      if (code == 400)
        return error({
          statusCode: 500,
          message: 'Can\'t launch application. Wrong Object ID'
        });
      if (code === 'a8ca569c-d1ac-4179-9f60-ef0e2caf92cf')
        return await store.dispatch('updateAuth', null);
    }
  }

  if (route.name === 'auth-signup') return;

  // Check Object ID
  const { isRegistrationAllowed, authType } = store.state.application;

  // Registration account without authentication
  if (!objectId && isRegistrationAllowed && !authType)
    return await store.dispatch('register', applicationId);

  // Search or Registration account with authentication (Emai/Phone [Set PIN])
  if (!objectId)
    return redirect('/auth/signup', {...route.query});

}
