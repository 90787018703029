<template>
  <div :class="{'is-offline': $nuxt.isOffline}">

    <div class="status-line" :class="{'status-line__material': isMD}" v-if="$nuxt.isOffline">
      <div>
        <p>You are offline</p>
        <span v-if="lastUpdateDate">the data are up to date from {{ lastUpdateDate | formatDate }}</span>
      </div>
    </div>

    <nuxt/>

    <!-- <v-ons-modal :visible="isPreload" class="is-white">
      <v-ons-progress-circular indeterminate></v-ons-progress-circular>
    </v-ons-modal> -->

    <q-dialog :value="isPreload">
      <q-spinner-hourglass
          color="white"
          size="2em"
        />
      <!-- <q-card>
        <q-card-section class="text-center">
          <q-circular-progress
            indeterminate
            size="50px"
            :thickness="0.22"
            color="primary"
            track-color="grey-3"
            class="q-ma-md"
          />
        </q-card-section>
      </q-card> -->
    </q-dialog>

  </div>
</template>

<script>
  import widgetsSDK from '../utils/widgetsSDK';
  import _ from 'lodash';
  import formatDate from 'date-fns/format';
  import differenceInMinutes from 'date-fns/differenceInMinutes';
  import { QDialog, QCard, QCardSection, QCircularProgress, QSpinnerHourglass } from 'quasar'

  const isDev = (process.env.NODE_ENV === 'development');
  const isSW = !!navigator.serviceWorker;
  const WIDGETS_CONFIG_LOCAL_DEFAULT = {
    debug: true,
    tenant: isDev ? process.env.TENANT_NAME : null,
    server: isDev ? `${process.env.DOMAIN_NAME}.${process.env.TLD}` : null,
    lang: 'en',
  };
  const {protocol, hostname} = location;
  const prepareHostname = hostname
    .split('.')
    .map((item, ind) => item = ind == 0 ? `${item}-admin` : item)
    .join('.');
  const BASE_API_URL = isDev ? process.env.BASE_API_URL : `${protocol}//${prepareHostname}`;
  const widgetsSrc = isDev ? process.env.WIDGETS_SRC : `${BASE_API_URL}/front/dist/widgets/mobsted.js`;

  export default {
    components: { QDialog, QCard, QCardSection, QCircularProgress, QSpinnerHourglass },
    computed: {
      devmode: vm => vm.$devmode.isActive,
      lastUpdateDate: vm => vm.$store.state.lastUpdateDate,
      isPreload: vm => vm.$store.state.screen.isPreload,
      // isPreload: vm => true,
      prefetchImages: vm => vm.$store.state.prefetchImages,
      // isMD: vm => !vm.$ons.platform.isIOS(),
      isMD: vm => vm.$q.platform.is.android,
    },
    data: () => ({
      offlineMessage: '',
      // format: format,
      // differenceInSeconds: differenceInSeconds
    }),
    async head() {
      let newval = [];
      if (this.prefetchImages) {
        _.each(this.prefetchImages, (val) => {
          newval.push({rel: "prefetch", href: val});
        });
      }
      return {
        link: [
          {rel: 'preconnect', href: BASE_API_URL, crossorigin: true},
          {rel: 'dns-prefetch', href: BASE_API_URL, crossorigin: true},
          ...newval
        ],
      }
    },
    async created() {
      // lazy load all screens current Application
      this.$store.dispatch('getAllScreens');
      if (isSW) {
        navigator.serviceWorker.addEventListener('message', this.handleOfflineSync);
      }
    },
    async destroyed() {
      if (isSW)
        navigator.serviceWorker.removeEventListener('message', this.handleOfflineSync);
    },
    async mounted() {
      if (!this.devmode && !isDev) {
        await widgetsSDK.get(widgetsSrc);
        const config = this.getWidgetsConfig();
        this.widgetsInit(config);
      }
    },
    methods: {
      async handleOfflineSync(event) {
        let offlineMessage = '', postMessage = null;
        if (isSW) {
          if (event.data.message === 'updateOfflineMessage') {
            const isExcludedHost = [
              'knauf.mobsted.ru',
              'coostoff.mobsted.ru',
              'testevgen.mbst.xyz'
            ].includes(location.host)
            if (this.isMD || (!this.isMD && isExcludedHost)) {
              const isBackgroundSync = (await navigator.serviceWorker.getRegistration()).sync;
              const expireDate = await this.$store.dispatch('getTokenExpirationDate');
              // const formattedDate = moment(expireDate).format('DD.MM.YYYY HH:mm');
              const formattedDate = formatDate(expireDate, "dd.MM.yyyy HH:mm");
              if (isBackgroundSync) {
                offlineMessage = `Your device is OFFLINE. Auto sync will start, when online. For security reasons go online before - ${formattedDate}.`;
              }
              else {
                offlineMessage = `Your device is OFFLINE. When online, just open the app to auto sync. For security reasons do this before - ${formattedDate}.`;
              }
              // const duration = moment.duration(moment(expireDate).diff(moment(new Date())));
              // const timer = duration.asMinutes();
              const timer = differenceInMinutes(expireDate, new Date());
              postMessage = { timer: timer < 0 ? 0 : timer };
            }
            else {
              offlineMessage = 'Your device is OFFLINE and may not perform this operation properly. Please connect to the Internet and try again.';
              postMessage = {
                ios: true,
              };
            }
            if (event.data.quiet) {
              offlineMessage = null;
            }
          }
          if (event.data === 'resetOfflineMessage') {
            const isTokenNotExpired = await this.$store.dispatch('checkToken');
            if (!isTokenNotExpired) {
              offlineMessage = 'Your device is ONLINE, but your token is expired and requests would not be sent.';
            }
          }
          if (offlineMessage && offlineMessage !== this.offlineMessage) {
            // this.$ons.notification.alert(offlineMessage, { timeout: 5000 });
            this.$q.dialog({ title: 'Attention', message: offlineMessage });
          }
          this.offlineMessage = offlineMessage;
          if (postMessage) {
            event.ports[0].postMessage(postMessage);
          }
        }

      },
      /**
       * Получение концигурации виджетов
       *
       * @returns {Object}
       */
      getWidgetsConfig() {
        const host = window.location.hostname;
        let config = WIDGETS_CONFIG_LOCAL_DEFAULT;
        if (host !== 'localhost') {
          const aHost = host.split('.');
          if (aHost.length < 3) {
            console.error('Variable "aHost" is invalid!');
            return null;
          }
          const tenant = aHost.shift().replace('-admin', '');
          const server = aHost.join('.');
          config = {
            debug: WIDGETS_CONFIG_LOCAL_DEFAULT.debug,
            tenant,
            server,
            lang: WIDGETS_CONFIG_LOCAL_DEFAULT.lang,
          };
        }
        return config;
      },
      /**
       * Инициализация виджетов
       *
       * @param {Object} config
       * @returns {Promise<boolean>}
       */
      async widgetsInit(config) {
        if (!config) {
          console.error('Argument "config" is not found!');
          return false;
        }
        const {appid, objid, screenid} = this.$route.query;
        const commonConfig = {
          debug: config.debug,
          tenant: config.tenant,
          server: config.server,
          applicationId: appid,
          objectId: objid,
          screenId: screenid,
          lang: config.lang,
        };
        const widgets = window.mobsted;
        const {access_token} = this.$store.state.auth;
        if (widgets && access_token) {
          this.$nuxt.$on('global:currentScreenId', value => widgets.setCurrentScreenId(value));
          this.$nuxt.$on('global:beforeExecuteAction', value => widgets.beforeExecuteAction(value));
          // костыль для экшенов:
          this.$store._vm.$on('global:beforeExecuteAction', value => widgets.beforeExecuteAction(value));
          // this.$store._vm.$on('global:afterExecuteAction', () => {}); // TODO: сделать, если понадобится
          await widgets.init(commonConfig);
          // Событие при первичной загрузке приложения
          this.$nuxt.$emit('global:currentScreenId', screenid);
          return true;
        }
        return false;
      },
    },
  };
</script>

<style lang="scss">
  .status-line {
    font-size: 14px;
    text-align: center;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    height: 40px;
    color: white;
    background-color: #4e5154;
    padding: 5px;
    font-family: -apple-system, 'Helvetica Neue', 'Helvetica', 'Arial', 'Lucida Grande', sans-serif;

    p {
      margin: 0;
    }

    span {
      font-size: .75em;
    }
  }

  .status-line__material {
    font-family: 'Roboto', 'Noto', sans-serif;
  }

  .is-offline .screen {
    top: 40px;
  }

  .modal.is-white {
    background-color: rgba(255, 255, 255, .7);
  }
</style>
