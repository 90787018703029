import Vue from 'vue';

const instanceList = {};

/**
 * Создание шины событий
 * Можно создавать множество уникальных экземпляров
 *
 * @param {string} instanceId - id экземпляра
 * @returns {CombinedVueInstance}
 * @constructor
 */
const MakeEventBus = (instanceId = 'global') => {
  if (!(instanceId in instanceList)) {
    instanceList[instanceId] = new Vue();
  }

  return instanceList[instanceId];
};

export default MakeEventBus;
