import _ from 'lodash';
import {
  format as formatDate,
  parseISO as parseISODate,
  addMinutes
} from 'date-fns';
import { math } from './math';
import { isSuccessConditions, isSuccessConditionsWith } from './conditions';
import Vue from 'vue'

// ** need for using store in this file
// ** `import store/...` won't worked
var store;
export const passStateToHashtagsUtils = (s) => {
  store = s
}

/**
 * TODO (Andrey): most likely need refactoring
 *
 * @param {Array} components
 * @param {Object} hashtagsData
 */
export const getBackendnameHashtags = (components, hashtagsData) => {
  let i = 50;
  let isDone = false;
  let tmp = {};
  let hashtags = _.cloneDeep(hashtagsData);
  const arrRe = getRegExpTpl(hashtags);
  const backendnames = getBackendnames(components);

  // ** this legacy condition, must remove in future, when all `active` components will have `properties.value`
  const includesComponents = [
    'mbst-text-input', 'mbst-number-input', 'mbst-text-area', 'mbst-select',
    'mbst-checkboxes', 'mbst-radio-buttons', 'mbst-checkbox', 'mbst-radio', 'mbst-uploadfile'
  ];
  // **

  const getValueForBackendnameHashtag = (component) => {

    if (component.items && component.items.length) {
      component.items.forEach(item => {
        item.components.forEach(component => {
          getValueForBackendnameHashtag(component);
        })
      })
    }
    if (component.components && component.components.length) {
      component.components.forEach(component => {
        getValueForBackendnameHashtag(component);
      })
    }

    // ** this NEW condition for modern `active` components (that can generate some value)
    // ** must uncomment in future
    // if ( !backendnames.includes(component.properties.backendname) && !component.isAnchorLoop ){
    //   return;
    // }
    // **

    // ** this condition, for legacy components, that have not `properties.value`
    // ** must remove in future, when all `active` components will have `properties.value`
    if ( _.includes(includesComponents, component.name) && !component.isAnchorLoop ) {
    // **

      let val = component.properties.defaultvalue || component.properties.value || '';

      if ( ['mbst-checkboxes', 'mbst-radio-buttons', 'mbst-select'].includes(component.name) ) {
      // if (
      //   component.name == 'mbst-checkboxes' ||
      //   component.name == 'mbst-radio-buttons' ||
      //   component.name == 'mbst-select'
      // ) {
        val = component.properties.options;
      }

      let without = _.without(backendnames, component.properties.backendname);
      let bn = component.properties.backendname;

      // Заменим хэштеги из доступных уже
      _.each(arrRe, re => {

        // Radio, Select (singleselect mode)
        if (_.isPlainObject(val)) {
          _.each(val, (v, k) => {
            v = replaceHashtagsInMath(v, arrRe, hashtags, false);
            v = math(v);
            val[k] = replacer(v, hashtags, re, false, false);
          })
        }
        // Checkboxes, Select (multiselect mode)
        else if (_.isArray(val)) {
          _.each(val, (item, ind) => {
            _.each(item, (v, k) => {
              v = replaceHashtagsInMath(v, arrRe, hashtags, false);
              v = math(v);
              val[ind][k] = replacer(v, hashtags, re, false, false);
            });
          });
        }
        // Other
        else {
          val = replaceHashtagsInMath(val, arrRe, hashtags, false);
          val = math(val);
          val = replacer(val, hashtags, re, false, false);
        }

      })

      // Зачистим кривые хэштеги
      _.each(arrRe, (re, type) => {
        if (type !== 'Backendname') {
          if (_.isPlainObject(val)) {
            _.each(val, (v, k) => {
              val[k] = clearBadHashtags(v, re);
            })
          } else if (_.isArray(val)) {
            _.each(val, (item, ind) => {
              _.each(item, (v, k) => {
                val[ind][k] = clearBadHashtags(v, re);
              })
            })
          } else {
            val = clearBadHashtags(val, re);
          }
        }
      });

      // ** извлекаем default value из компонентов старого формата (mbst-radio-buttons, mbst-checkboxes)
      // ** на основании selected в их options
      if (Array.isArray(val)) {

        let selectedVal = null;

        if (component.properties.multiple) {
          for (const item of val) {
            const { conditions } = item;
            if (conditions && conditions.length > 0 && isSuccessConditions(conditions)) {
              item.selected = !item.selected;
            }
            delete item.conditions
          }
        } else {
          _.each(val, item => {
            const { conditions } = item;
            if (conditions && conditions.length > 0 && isSuccessConditions(conditions)) {
              item.selected = !item.selected;
            }
            
            if (item.selected) {
              if (selectedVal === null) {
                selectedVal = _.omit(item, ['selected', 'conditions'])
              } else {
                if (!Array.isArray(selectedVal)) {
                  selectedVal = [selectedVal];
                }
                selectedVal.push(_.omit(item, ['selected', 'conditions']));
              }
            }
          });
          val = (Array.isArray(selectedVal) && component.name == 'mbst-radio-buttons') ? selectedVal[0] : selectedVal;
        }
      }
      // **


      if (_.isPlainObject(val)) {
        _.each(val, (v, k) => {
          let {str, isDone: r} = createHashtagsFromBackendnames(v, arrRe, without);
          if (!r) {
            isDone = r;
          } else {
            val[k] = str;
          }
        })
        //tmp[bn] = hashtags.Backendname[bn] = val;
      }
      else if (_.isArray(val)) {
        _.each(val, (item, ind) => {
          // item.type = 'checkboxes';
          _.each(item, (v, k) => {
            let {str, isDone: r} = createHashtagsFromBackendnames(v, arrRe, without);
            if (!r) {
              isDone = r;
            } else {
              if (k == 'conditions') {
                /**
                 * TODO: MOB-4140
                 * Кондишены для чека и анчека
                 */
                const { conditions } = val[ind];
                if (conditions && conditions.length > 0 && isSuccessConditions(conditions)) {
                  val[ind].selected = !val[ind].selected;
                }
              }
              if (k != 'selected') val[ind][k] = str;
            }
          })
        })
        //tmp[bn] = hashtags.Backendname[bn] = val;
      }
      else {
        let {val: str, isDone: r} = createHashtagsFromBackendnames(val, arrRe, without);
        if (!r) { isDone = r } else {
          //tmp[bn] = hashtags.Backendname[bn] = val;
        }
      }

      if (isDone) {
        // ** извлекаем default value из компонентов нового формата с не скалярным value (mbst-radio, mbst-checkbox)
        // ** определяем такие компоненты по наличию свойства selected
        if (component.properties.selected !== undefined) {

          // ** check, if component.value already in hashtags
          let htValue = Array.isArray(hashtags.Backendname[bn]) ? hashtags.Backendname[bn] : (hashtags.Backendname[bn] ? [hashtags.Backendname[bn]] : []);
          let contains = _.map(htValue, 'value').indexOf(component.properties.value);

          // ** component value is already contains in hashtags.Backendname
          if (!!component.properties.multiple && contains > -1){
            if (Array.isArray(hashtags.Backendname[bn])) {
              hashtags.Backendname[bn][contains].selected = !!component.properties.selected;
            } else if (contains === 0) {
              hashtags.Backendname[bn].selected = !!component.properties.selected;
            }
            tmp[bn] = hashtags.Backendname[bn]
            return isDone;
          } else if (!component.properties.multiple && (!component.properties.selected || component.properties.iscached)){
            return isDone;
          }


          // ** component value not contains in hashtags.Backendname now
          // **

          // ** modify value to Object {label, value}
          const label = component.properties.label !== undefined ? component.properties.label : component.properties.value;
          val = { label, value: component.properties.value };

          // ** combine value with other options (for multiselect objects)
          if ( !!component.properties.multiple ){
            val.selected = !!component.properties.selected;
            if ( hashtags.Backendname[bn] ) {
              val = htValue.push(val) && htValue;
            }
          }
        }
        // **

        if (hashtags.Backendname[bn] === undefined || !component.properties.iscached) {
          tmp[bn] = hashtags.Backendname[bn] = val;
        }
      }

    }

    return isDone;
  }

  while(!isDone && i > 0) {
    i--;
    isDone = true;

    _.each(components, component => {
      isDone = getValueForBackendnameHashtag(component);
    })
  }

  return tmp;
}

const clearBadHashtags = (str, regExrPtl) => {
  str = JSON.stringify(str);
  if (str.search(regExrPtl) != -1) {
    _.forEach( str.match(regExrPtl), key => { str = str.replace(key, '') } )
  }
  return JSON.parse(str);
}

const createHashtagsFromBackendnames = (str, arrRe, without) => {
  str = JSON.stringify(str);
  let isDone = true;
  if (str.search(arrRe.Backendname) != -1) {
    _.each(str.match(arrRe.Backendname), key => {
      let b = key.match(/#Backendname:(.+?)(?=#|:|@)/)[1];
      str = str.replace(key, '');
      if ( _.includes(without, b) ) {
        isDone = false;
      }
    })
  }
  str = math(str);
  str = JSON.parse(str);
  return {str, isDone};
}


/**
 *
 * @param {Object} obj
 */
export const getRegExpTpl = obj => {
  let res = {};
  _.each(_.keys(obj), key => {
    // res[key] = new RegExp(`#(${key}:[^\\s#\\"\\\\]+)#`, 'g'); // ** строгий шаблон: окончанием хэштега (кроме #) считается пробел, двойная кавычка, слэш
    res[key] = new RegExp(`#(${key}:.+?)#`, 'g'); // ** не строгий шаблон: окончанием хэштега считается только #
  });
  return res;
}


export const replaceHashtagsInLoop = (component, hashtags, tag) => {
  ['actions', 'components', 'items', 'properties', 'css'].forEach(key => {
    if (!component[key]) return;
    let str = JSON.stringify(component[key]);
    let re = new RegExp('#?(' + tag+ ':[^#]+)#', 'g');
    let tags = str.match(re);
    if (!tags) return;

    tags = new Set(tags); // ** exclude duplicates

    let arrRe = getRegExpTpl(_.omit(hashtags, 'Loop'));

    tags.forEach(tag => {
      let val = getHastagValue(_.trim(tag, '# '), hashtags, true, false, false);

      if (val !== undefined && typeof val !== 'object' && !Array.isArray(val)) {
        let reg = new RegExp(escapeRegExp(tag), 'g');
        val = JSON.stringify(val)
        if (_.startsWith(val, '"') && _.endsWith(val, '"')) val = val.slice(1, -1)
        str = str.replace(reg, val)
      }
    });

    let isClear = true
    str = replacer(str, hashtags, /#CurrentDateTime@{0,1}.+?#/g, isClear, false, true);
    str = replaceHashtagsInMath(str, arrRe, hashtags, isClear);
    _.each(arrRe, re => {
      str = replacer(str, hashtags, re, isClear, false, false);
    });
    if (isClear) str = math(str);

    try {
      component[key] = JSON.parse(str);
    }
    catch(e) {
      console.error(e)
      console.warn(tag);
      // console.warn(key);
      // console.warn(str);
    }
  })

  return component;
}


/**
 * More precise hashtag replacing, based on store.state.dependencies.components
 * process only scalar properties that contains hashtags
 *
 * @param {Object} data
 * @param {Object} hashtagsData
 * @returns {Object}
 */
export const replaceHashtagsByDependencies = (dependencies, hashtagsData, isClear = true) => {
  try {
    const devmodeIsActive = Vue.prototype.$devmode.isActive
    // if (devmodeIsActive && !store.rootState.renderHashtags) return

    dependencies = dependencies || store.state.dependencies.components;
    hashtagsData = hashtagsData || store.rootState.hashtags;

    const exceptTag = "#Loop:"

    for (let el of dependencies) {
      if (!el) return
      let component = store.getters.component({uuid: el.uuid});

      if (!component) continue

      let paths = el.paths

      if ((component || {}).loopData) {
        const { localHashtagPaths } = component.loopData

        let loopHastags = {
          Loop: {}
        }

        for (let i = 0, keys = Object.keys(localHashtagPaths); i < keys.length; i++) {
          const loopName = keys[i]
          const loopPath = localHashtagPaths[loopName]
          const __index = loopPath.split('.').pop()
          loopHastags.Loop[loopName] = {
            ..._.get(hashtagsData, loopPath),
            __index
          }
        }
        
        hashtagsData = {
          ...hashtagsData,
          ...loopHastags
        }

      } else {
        paths = el.paths.filter(item => item.value.indexOf(exceptTag) === -1)
      }

      let arrRe = Object.values(getRegExpTpl(hashtagsData))

      for (let item of paths) {
        let str = item.value;

        str = replacer(str, hashtagsData, /#Loop:.+?#/g, false, false, false);
        str = replacer(str, hashtagsData, /#CurrentDateTime@{0,1}.+?#/g, isClear, false, true);
        str = replaceHashtagsInMath(str, hashtagsData, isClear);

        for (let re of arrRe) {
          str = replacer(str, hashtagsData, re, isClear, false, false)
        }
        if (isClear) str = math(str);

        if (item.path === 'properties.defaultvalue' || item.path === 'properties.value') {
          store.dispatch('changeComponentValue', { value: str, uuid: component.uuid });
        } else {
          _.set(component, item.path, str);
        }
      }
    }
  }
  catch(error) {
    console.error(error)
  }
}

const replaceHashtagsInMath = (str, hashtagsData, isClear) => {

  let arrRe = getRegExpTpl(hashtagsData);
  const reMath = /=\(.+?\)=|=\(\)=/g;
  if (_.isString(str) && str.search(reMath) != -1) {
    let tempStr = str;
    _.each(tempStr.match(reMath), key => {
      let temp = key.replace(/=\((.+?)\)=/, '$1');
      let res = temp;
      _.each(arrRe, re => {
        res = replacer(res, hashtagsData, re, isClear, true, false);
      });
      tempStr = tempStr.replace(temp, res);
    })
    str = tempStr;
  }
  return str;
}

const getDate = () => {
  if (!Date.now) {
    Date.now = function now() {
      return new Date().getTime();
    };
  }
  return Date.now();
}
/**
 *
 * @param {String} str
 * @param {Object} hashtags
 * @param {RegExp} regExpTpl
 * @param {Boolean} isClear
 * @returns {String}
 */
export const replacer = (str, hashtags, regExpTpl, isClear, isMath = false, isDate = false) => {

  let isParse = false;
  if (!_.isString(str)) {
    str = JSON.stringify(str);
    isParse = true;
  }

  let keys = str.match(regExpTpl);
  if (!keys) {
    return isParse ? JSON.parse(str) : str;
  }

  keys = new Set(keys); // ** exclude duplicates

  let key;
  for (key of keys) {
    let res = getHastagValue(_.trim(key, '#" '), hashtags, isClear, isMath, isDate);

    if (res === undefined) continue;

    if (_.isPlainObject(res)) {
      res = res.value || '#Object#';
    } else if (Array.isArray(res)) {
      res = '#Array#';
    }

    let reg = new RegExp(escapeRegExp(key), 'g');
    str = str.replace(reg, res);
  }

  return isParse ? JSON.parse(str) : str;
}

/**
 *
 * @param {String} tag
 * @param {Object} hashtags
 * @param {Boolean} isClear
 * @returns {String}
 */
export const getHastagValue = (tag, hashtags, isClear, isMath = false, isDate = false) => {

  // найти модификатор
  let reFunc = /@(?<func>[^@]+?)\s*\((?<params>.*?)\s*\)\s*(?<pathFunc>[\[\.\:].*?$)|@(?<funcOnce>[^@]+?)\s*\((?<paramsOnce>.*?)\s*\)\s*/gi;
  const reFuncExec = reFunc.exec(tag);
  let func = null;
  let params = null;
  let pathFunc = null;
  if (reFuncExec) {
    func = reFuncExec.groups.func || reFuncExec.groups.funcOnce;
    params = reFuncExec.groups.params || reFuncExec.groups.paramsOnce;
    pathFunc = reFuncExec.groups.pathFunc || null;
    tag = tag.replace(/@[^@]*?\(.*?\).*/, '');
    if (params.indexOf(',') > -1) {
      // params = params.split(/\s*,\s*/)
      params = params.match(/'.*?'/gi) || params.split(/\s*,\s*/)
      params.forEach((el, i) => params[i] = _.trim(el, '"\''))
    }
  }

  let path = tag.replace(/\:/g, '.');
  if (pathFunc) pathFunc = pathFunc.substring(1)
    .replace(/\:/g, '.');

  let res = isClear ? _.get(hashtags, path, '') : _.get(hashtags, path);

  if (isDate) res = new Date();
  if (func && functions[func]) res = functions[func](res, params);
  if (pathFunc) res = isClear ? _.get(res, pathFunc, '') : _.get(res, pathFunc);
  if (isMath && !res) res = 0;

  return res
}


/**
 *
 * @param {String} str
 * @returns {String}
 */
export const escapeRegExp = str => str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");


/**
 * Get list of names of components, that can generate value (i.e. have `property.value`)
 *
 * @param {Object} components
 * @returns {Array}
 */
const getBackendnames = (components) => {
  let backendnames = new Set();

  const _get = (components) => {
    if (!components) return;
    components.forEach(component => {

      // ** this block for new components, that have `property.value`
      // ** uncomment later
      // if (component.properties.value !== undefined){
      //   backendnames.add(component.properties.backendname);
      // }
      // **

      // ** this block for lagacy components that may not have `property.value`
      // ** replace with previous block in future
      if (component.properties) backendnames.add(component.properties.backendname);
      //

      if (component.items && component.items.length) {
        //component.items.forEach(item => _get(item.components))
        _get(component.items)
      }
    });
  };

  _get(components);
  return [...backendnames];
}


export const functions = {
  /**
   *
   * @param {Array} data
   * @param {Array} params
   * @returns {Number}
   */
  sum(data, params) {
    if (params) {
      params = params.match(/("[^"]+")/g);
      params = _.map(params, param => param.replace(/"/g, ''));
    }
    const key = params ? params[0] : null;
    return _.reduce(data, (result, value) => result + Number(key ? value[key] : value), 0);
  },

  /**
   * #Backendname:checkboxes@join('-', 'label', 'selected = true')#
   * @param {Array} data
   * @param {Array} params
   * @returns {String}
   */
  join(data, params) {
    let res = ''
    try {
      const delimiter = params ? params[0] : '-';
      const key = params ? params[1] : null;
      const comparator = params[2] || null;

      const regex = /(?<property>.*?)\s*(?<operator>\<\>|\>=|\>|\<=|\<|\=\=|=|\!\=)\s*(?<value>.*?)\s*(?<ayrac>&&|\|\|)*(\s|$)/gmi;

      let blockOr = [];
      let blockAnd = [];
      let m;

      if (comparator) {
        while ((m = regex.exec(comparator)) !== null) {
          // This is necessary to avoid infinite loops with zero-width matches
          if (m.index === regex.lastIndex) {
            regex.lastIndex++;
          }
          let { property, operator, value, ayrac } = m.groups;
          property = _.trim(property, '"\' ')
          value = _.trim(value, '"\' ')
          const path = property.replace('filterItem:', '').replace(/\:/, '.');
          blockAnd.push({ path, property, operator, value });
          if (ayrac == '||') {
            blockOr.push(blockAnd);
            blockAnd = [];
          }
        }
        if (!_.isEmpty(blockAnd)) blockOr.push(blockAnd);
      }

      let result = _.isArray(data) ? data : [data]
      if (comparator) result = data.filter(filterItem => isSuccessConditionsWith(blockOr, filterItem))

      res = result.map(item => key ? item[key] : item).join(delimiter)
    }
    catch(error) {
      console.error(error)
    }
    finally {
      return res
    }
  },

  /**
   *
   * @param {Object} data
   * @param {Array} params
   * @returns {String}
   */
  toString(data, params) {
    const delimiter1 = params[0] || '&';
    const delimiter2 = params[1] || '=';

    let result = []
    for (let key in data) {
      result.push( key + delimiter2 + data[key])
    }
    return result.join(delimiter1)
  },

  /**
   * Format Date
   * @param data
   * @param params
   * @returns {string|*}
   */
  formatDate(data, params) {
    if (!params) return data;

    const format = Array.isArray(params) ? params[0] : params
    const isTZ = Array.isArray(params) ? !!Number(params[1]) : !!0

    const dataBack = data

    if (!(data instanceof Date)) data = parseISODate(data)

    if (isTZ) data = addMinutes(data, -data.getTimezoneOffset())

    if (data.toString() === 'Invalid Date') {
      console.log("Error date converting in @formatDate", dataBack);
      return dataBack;
    }

    return formatDate(data, format.replace(/\\\"/g, ''));
  },

  /**
   * #GroupHashtag:Name:Array@filter(filterItem = "some text")#
   * #GroupHashtag:Name:Array@filter(filterItem:nested.name = "some text")#
   * =, ==, !=, <>, <, <=, >, >=
   * @param data
   * @param params
   */
  filter(data, params) {

    const regex = /(?<property>.*?)\s*(?<operator>\<\>|\>=|\>|\<=|\<|\=\=|=|\!\=)\s*(?<value>.*?)\s*(?<ayrac>&&|\|\|)*(\s|$)/gmi;

    let blockOr = [];
    let blockAnd = [];
    let m;

    while ((m = regex.exec(params)) !== null) {
      // This is necessary to avoid infinite loops with zero-width matches
      if (m.index === regex.lastIndex) {
        regex.lastIndex++;
      }
      let { property, operator, value, ayrac } = m.groups;
      property = _.trim(property, '"\' ')
      value = _.trim(value, '"\' ')
      const path = property.replace('filterItem:', '').replace(/\:/, '.');
      blockAnd.push({ path, property, operator, value });
      if (ayrac == '||') {
        blockOr.push(blockAnd);
        blockAnd = [];
      }
    }
    if (!_.isEmpty(blockAnd)) blockOr.push(blockAnd);
    return _.filter(data, filterItem => isSuccessConditionsWith(blockOr, filterItem));
  },

  /**
   * Форматирование числа
   * @param data
   * @param params Not used now
   */
  formatNumber (data, params) {
    const num = Number(data)
    if (isNaN(num)) return data;
    return num.toLocaleString()
  },

  /**
   * Удаление символов из строки
   * @param data Исхоная строка
   * @param params  Строка из удаляемых символов
   */
  trim (data, params) {
    if (!data) return data
    if (Array.isArray(params)) {
      params = `${params[0]},${params[1]}`
    }
    params = params.replace('\\', '\\\\\\\\').replace('/', '\/')
    return data.toString().replace(new RegExp(`[${params}]+`, 'g'), '')
  },

  /**
   * Method extracts a section of a string/array and returns it as a new string/array
   * @param {Array|string} data
   * @param {Array} params - start, end indexes
   * @returns {*}
   */
  slice(data, params) {
    try {
      if (!data) return data
      if (!Array.isArray(params))
        params = [params]

      const start = Number(params[0]);
      const end = params[1] ? Number(params[1]) : data.length;
      return data.slice(start, end);
    }
    catch(e) {
      console.error(e);
      return data;
    }
  },

  length(data) {
    try {
      return data.length
    }
    catch(e) {
      console.error(e);
      return data
    }
  }

}
