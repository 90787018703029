import _ from 'lodash';

export const isSuccessConditions = conditions => {
  const orRules = _.map(conditions, andRules => isCompareAnd(andRules));
  return !!_.find(orRules, item => item);
};

export const isSuccessConditionsWith = (conditions, data) => {
  const orRules = _.map(conditions, andRules => isCompareAndWith(andRules, data));
  return !!_.find(orRules, item => item);
};

export const checkConditions = ({ logicalOperator, rules }) => {
  let res;
  if (rules.length>0) {

    switch (logicalOperator) {
      case 'AND':
        res = isCompareAnd(rules);
        break;
      case 'OR':
        res = isCompareOr(rules);
        break;
    }

  }
  return res;
}


const isCompareAndWith = (rules, data) => !!!_.find(rules, rule => {
  return !isCompare({
    ...rule,
    property: rule.path ? _.get(data, rule.path) : data,
  });
});
const isCompareAnd = rules => !!!_.find(rules, rule => !isCompare(rule));
const isCompareOr = rules => !!_.find(rules, rule => isCompare);

/**
 *
 * @param {Object} rule
 * @param {string} rule.operator
 * @returns {Boolean}
 */
const isCompare = ({ operator, property, value}) => {
  if (!operator) return false
  const func = {
    '=': _.eq,
    '!=': _.eq,
    '<>': _.eq,
    '>': _.gt,
    '>=': _.gte,
    '<': _.lt,
    '<=': _.lte,
  };
  // https://way2mob.atlassian.net/browse/MOB-4409
  // if (property === '') return false; // If property is not set, theres nothing to compare
  let floatProperty = tryGetFloatFromString(property);
  let floatValue = tryGetFloatFromString(value);
  if (isNaN(floatProperty) || isNaN(floatValue)) { // if one of them cant be converted to number, compare given values
    if (operator === 'regexp') {
      const r = new RegExp(value, 'gi');
      return r.test(property);
    }

    // гениально блеать
    // we must compare empty strings
    return (operator === '!=' || operator === '<>') ?
      !func[operator](_.isNull(property) ? '' : _.toString(property), value ? value : '') :
      func[operator](_.isNull(property) ? '' : _.toString(property), value ? value : '');
  }
  else { // if both property and value can be converted to number, lets compare numbers instead of strings
    return (operator === '!=' || operator === '<>') ?
      !func[operator](floatProperty, floatValue) :
      func[operator](floatProperty, floatValue);
  }
};

const tryGetFloatFromString = value => {
  if (/^(\-|\+)?([0-9]+(\.[0-9]+)?|Infinity)$/.test(value)) {
    return Number(value);
  }
  return NaN;
};
