export default ctx => action =>
  new Promise(async resolve => {
    const { type } = action.options;
    if (type == 'screen') {

      // ctx.store.commit('screen/setIsPreload', true);

      let query = Object.assign({}, ctx.query);
      if (query.screenid != action.options.screenId) {
        query.screenid = action.options.screenId;
      }
      ctx.app.router.push({ query });
    }

    if (type == 'url') {
      let { url } = action.options;
      if ( !url.match(/^.+?:/i) ) url = 'http://' + url;

      if (action.options.isOpenNewWindow) {
        const win = window.open(url, '_blank');
        if (!win) window.location = url; // sometimes a browser blocks pop-up windows (ios safari)
      } else {
        window.location = url;
      }
    }

    if (type == 'back') {

      // ctx.store.commit('screen/setIsPreload', true);

      if (history.length > 1) {
        ctx.app.router.back();
      } else {
        // Go to home screen
        // let query = Object.assign({}, this.$route.query);
        // query.screenid = 3420;
        // this.$router.replace({query});
      }
    }

    resolve();
  });
