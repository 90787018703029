import MakeEventBus from '../utils/MakeEventBus';
const isSW = !!navigator.serviceWorker;

/**
 * Check connection
 * @param event
 */
const handleConnectionChange = async event => {
  if (event.type == 'online') {
    if (isSW) navigator.serviceWorker.controller.postMessage('replayRequests');
  }

  if (event.type == 'offline') {

  }
};

export default async ({ store, route, $actions }) => {
  const appInstalled = () => {
    if (store.state.pwa.installed) return
    const date = Date.now()
    const installed = { createdAt: date, updatedAt: date }
    const actions = [
      { type: 'createEvent', options: { actionName: 'Installed' } },
      { type: 'gtagEvent', options: { eventName: 'pwa_installed' } },
    ]
    window.mbst.bus.$emit('global:actionsExecute', { actions })
    window.mbst.A2HS.isInstalled = true
    store.commit('setState', { key: 'pwa', value: { installed } })
  }

  const appLaunched = () => {
    const appId = store.state.application.id
    const objId = store.state.object.id
    const key = `pwa_${appId}_${objId}`
    const lastLaunchInSession = sessionStorage.getItem(key)

    if (!lastLaunchInSession) {
      const time = Date.now()
      sessionStorage.setItem(key, String(time))
      const actions = [{ type: 'gtagEvent', options: { eventName: 'pwa_launched' } }]
      $actions.execute({ actions }) //window.mbst.bus.$emit('global:actionsExecute', { actions })
    }
  }

  window.mbst = {
    A2HS: {
      isInstalled: true,
      deferredPrompt: null,
      prompt() {
        this.deferredPrompt.prompt()
        this.deferredPrompt.userChoice.then((choiceResult) => {
          let actionName = 'Install Rejected'
          let eventName = 'pwa_install_dismissed'

          if (choiceResult.outcome === 'accepted') {
            eventName = null
            actionName = 'Install Accepted'
            appInstalled()
            document.dispatchEvent(new Event('promptInstalled'))
          }

          const actions = [{ type: 'createEvent', options: { actionName } }]

          if (eventName) actions.push({ type: 'gtagEvent', options: { eventName } })

          window.mbst.bus.$emit('global:actionsExecute', { actions })
          this.deferredPrompt = null
        })
      },
    },
    bus: null,
  }

  window.mbst.bus = MakeEventBus('global')

  window.addEventListener('beforeinstallprompt', (e) => {
    e.preventDefault()

    let { installed } = store.state.pwa

    if (installed) {
      const actions = [{ type: 'gtagEvent', options: { eventName: 'pwa_deleted' } }]
      window.mbst.bus.$emit('global:actionsExecute', { actions })
    }

    installed = null

    store.commit('setState', { key: 'pwa', value: { installed } })

    window.mbst.A2HS.isInstalled = false
    window.mbst.A2HS.deferredPrompt = e
    document.dispatchEvent(new Event('promptInstall'))
  })

  window.addEventListener('appinstalled', () => {
    appInstalled()
  })

  store.commit('application/setStateByKey', { key: 'isStopReq', payload: false });

  let displayMode = 'browser tab'

  const mqls = [
    window.matchMedia('(display-mode: standalone)'),
    window.matchMedia('(display-mode: fullscreen)'),
    window.matchMedia('(display-mode: minimal-ui)'),
  ]

  if (navigator.standalone)
    displayMode = 'standalone-ios'

  if (mqls.some((mql) => mql.matches))
    displayMode = mqls.find((mqls) => mqls.matches).media.replace(/^\(display-mode:\s(.+)\)/g, '$1')

  let { installed } = store.state.pwa

  if (displayMode !== 'browser tab') {
    const now = Date.now()

    if (!installed)
      installed = {
        createdAt: now,
      }

    installed.updatedAt = now

    store.commit('setState', { key: 'pwa', value: { installed } })
  }

  for (const mql of mqls) {
    mql.addEventListener('change', e => {
      if (!e.matches) return

      let { installed } = store.state.pwa

      if (!installed) {
        appInstalled()
      }
      else {
        installed.updatedAt = Date.now()
        store.commit('setState', { key: 'pwa', value: { installed } })
      }

      appLaunched()
    })
  }

  await store.dispatch('application/initFirebaseMessaging');
  window.mbst.bus.$on('global:messagingGetTokenAndSendToServer', async () => {
    return await store.dispatch('application/getTokenAndSendToServer');
  });

  window.addEventListener('online', handleConnectionChange);
  window.addEventListener('offline', handleConnectionChange);

  if (isSW) {
    navigator.serviceWorker.addEventListener('message', event => {
      console.log(event);
    });
  }

  if (store.state.application.id) store.dispatch('application/setAuthInfo')
  else await store.dispatch('application/setAuthInfo')

  if (store.state.object.id) store.dispatch('object/setAuthInfo')
  else await store.dispatch('object/setAuthInfo')

  if (displayMode !== 'browser tab') appLaunched()

}
