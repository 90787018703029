import { prepateJsonForList } from '../utils'

export default ctx => action =>
  new Promise(async (resolve, reject) => {
    try {
      const { listId, tableName, tableFields, json } = action.options
      let data = { listId, tableName }

      if (!listId || !!!Number(listId)) throw { message: 'List ID should be positive integer' }
      if (!tableName) throw { message: 'Table Name should not be empty' }
      if (!tableFields || !json) throw { message: 'No data is set' }

      data.json = JSON.stringify(prepateJsonForList(json, tableFields))
      data = new URLSearchParams(data)
      const { errors } = await ctx.$axios.$post('/list', data)

      if (!!errors) throw { errors }

      resolve()
    }
    catch (e) {
      console.error('[CREATE_RECORD_IN_TABLE_OF_LIST]: ', e)
      const { errors } = (e.response || {}).data || {}
      let options = { title: 'Error', message: 'Undefined error' }

      if (!errors) {
        if (e.title) options.title = e.title
        if (e.message) options.message = e.message
        ctx.app.$q.dialog(options)
      } else {
        for (const error of errors) {
          if (error.title) options.title = error.title
          if (error.detail) options.message = error.detail
          ctx.app.$q.dialog(options)
        }
      }

      reject()
    }
  })
