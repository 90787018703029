export default () => ({
  id: null,
  name: null,
  iconURL: null,
  firstAuthenticationScreen: null,
  authType: null,
  authTitle: null,
  homeScreen: null,
  isRegistrationAllowed: null,
  isIdentityEveryLogin: null,
  returnVisitorTimeout: 0,
  pushEndpoints: {
    // [ObjectId]: endpoint
  },
  isStopReq: false,
  externalAnalytics: {},
});
