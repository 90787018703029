import _ from 'lodash';

export default ctx => function (action) {
  new Promise(resolve => {

    // ** 'this' binds to current VueComponent in buttonMixin and then passes in actions.execute
    if (!this.executeScript) return;
    this.executeScript(action.options.value).then(() => resolve())
  });
}
