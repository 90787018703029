export default async ({ store, route, redirect }) => {
  const isToken = await store.dispatch('checkToken');
  const path = route.query.appid ? `/${route.query.appid}` : '/';

  if (isToken && route.name === 'auth-signin') return redirect(path, { ...route.query });
  if (isToken) return;
  if (route.name === 'auth-signup' || route.name === 'auth-signin' || route.name === '404') return;

  return await store.dispatch('getAccess');
}
