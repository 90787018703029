export default {

  /**
   * Get Auth Info Object and set in store state
   * @param commit from context
   * @returns {Promise<*>}
   */
  async setAuthInfo({ commit }) {
    const { appid: applicationId, objid: objectId } = this.app.context.query;

    try {
      if (objectId) {
        const objInfo = await this.$axios.$get('/auth/info/object', {
          params: { applicationId, objectId }
        });
        if (!objInfo) throw { errors: [{ title: 'Error', detail: 'Object info undefined' }] };

        const objectAttr = (objInfo.data || [])[0].attributes;
        if (!objectAttr) throw { errors: [{ title: 'Error', detail: 'Object info undefined' }] };

        // Update Object Info
        commit('setState', objInfo.data[0]);
      }
      return Promise.resolve({ isOk: true });
    } catch (e) {
      const { errors } = (e.response || {}).data || e;
      commit('setState', {attributes: { errors }});
      return Promise.resolve({ isOk: false, errors });
    }
  }
}
