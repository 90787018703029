const isDev = (process.env.NODE_ENV === 'development');
const { protocol, hostname } = location;
const prepareHostname = hostname
  .split('.')
  .map((item, ind) => item = ind == 0 ? `${item}-admin` : item)
  .join('.');
const BASE_URL = isDev ? process.env.BASE_API_URL : `${protocol}//${prepareHostname}`;

export default {

  setStateByKey(state, { key, payload }) {
    state[key] = payload;
  },

  mergeStateByKey(state, { key, payload }) {
    state[key] = Object.assign(state[key], payload);
  },

  setState(state, payload) {
    state.id = Number(payload.id);
    state.name = payload.attributes.Name;
    state.iconURL = payload.attributes.mainThumbnailImageUrl ?
      `${BASE_URL}${payload.attributes.mainThumbnailImageUrl}` : '/logo.svg';
    state.firstAuthenticationScreen = payload.attributes.firstAuthenticationScreen;
    state.homeScreen = payload.attributes.home_screen;
    state.authType = payload.attributes.authType;
    state.isRegistrationAllowed = (payload.attributes.registrationAllowed == 1);
    state.returnVisitorTimeout = payload.attributes.return_visitor_timeout || 0;
    /**
     * MOB-5179 = every login должен работать только на extra security
     * Временно выставим статичное значение до уточнения
     */
    state.isIdentityEveryLogin = false; //(payload.attributes.everyTime == 1);
    state.codeConfirmationTime = payload.attributes.codeConfirmationTime || 60;
    state.externalAnalytics = payload.attributes.Params.externalAnalytics || {}
  },

  setPushEndpoint(state, { endpoint, objectId }) {
    state.pushEndpoints[objectId] = endpoint;
  },

}
