import _ from 'lodash';
import localforage from 'localforage';

const func = async ({ store, query }, inject) => {
  try {
    const { appid, objid } = query;
    const APP_OBJ_STORE_NAME = `appid_${appid}_objid_${objid}`;
    const DB_NAME = 'MOBSTEDAPP';
    const STATE_STORE_NAME = 'state';
    const AUTHS_STORE_NAME = 'auth_list';
    const baseConfig = {
      driver: [localforage.INDEXEDDB, localforage.WEBSQL, localforage.LOCALSTORAGE],
      name: DB_NAME,
    };
    let idb = {};

    if (window.indexedDB) {
      const request = window.indexedDB.open(DB_NAME, 100);

      request.onsuccess = ev => {
        const db = ev.target.result;
        db.onversionchange = () => {
          if (!document.hidden) {
            db.close();
            window.location.reload();
          }
        }
      }

      request.onupgradeneeded = ev => {
        const db = ev.target.result;
        const storeNames = [ ...db.objectStoreNames ].filter(name => name.indexOf('appid_') !== -1 || name === 'mu');
        for (const storeName of storeNames) {
          db.deleteObjectStore(storeName);
        }
      }
    }

    await localforage.ready()

    idb.authList = localforage.createInstance({ ...baseConfig, storeName: AUTHS_STORE_NAME });
    idb.state = localforage.createInstance({ ...baseConfig, storeName: STATE_STORE_NAME });

    inject('idb', idb);

    if (appid && objid) {
      const localState = await idb.state.getItem(APP_OBJ_STORE_NAME);

      store.commit('restoreState', _.pick(localState, [
        'devmode', 'pwa',
        'application', 'object',
        'screens', 'hashtags',
        'lastUpdateDate', 'prefetchImages',
      ]));
    }
  }
  catch (e) {
    console.error(e);
  }
};


export default func;
