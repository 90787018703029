import Vue from 'vue'
import VueGtag from 'vue-gtag'

export default ({ store }, inject) => {
  const { ga } = store.state.application.externalAnalytics
  Vue.use(VueGtag, {
    config: {
      id: ga,
      params: {
        send_page_view: true
      }
    },
  })

  inject('gtag', Vue.prototype.$gtag)
}