import _ from 'lodash';
import MakeEventBus from '../MakeEventBus';

const EventBus = MakeEventBus('widgets');
export default ctx => {
  return action => {
    return new Promise(async (resolve, reject) => {
      //////////////////nko

      let Value = {};
      let backendnameHashtagsValues = _.cloneDeep(ctx.store.state.hashtags.Backendname);
       // console.log('action.options.');
       // console.log(action.options);
       // console.log('action.options.sngbbank');
       // console.log(action.options.sngbbank);
      // console.log();

      //////////////////nko
      if (action.options.selectedSystem == 'nko'){
        _.each(action.options.nko, (value, key) => {
          Value[key] = value;
        });
      _.each(action.options.value, item => {
        Value[item.key] = item.value;
      });

      await ctx.store.dispatch('createEvent', {
        ActionName: action.options.actionName,
        Value,
      });// After that the LastEvent hashtag will be updated
      ctx.store.commit('hashtags/mergeStateByKey', {
        key: 'Backendname',
        data: backendnameHashtagsValues
      });
      let NKO = await signAndGetBankParams(ctx, action.options.nko);
      if (NKO) {
        Value['merchant'] = NKO.merchant;
        Value['terminal'] = NKO.terminal;
        //Value['ActionName'] = 'Оплата';//very necessary attirbutes for process of work with NKO system
        Value['paymentsystem'] = 'nko';
        await ctx.store.dispatch('updateEvent', {
          id: NKO.eventId,
          Value,
        });
        submitPaymentForm(NKO);
        // TODO как то реагировать если данные не были сохранены
        resolve();
      } else {
        //console.log('Not enough data for signing form');
        reject();
      }
    }

      //////////////////sngb
      if (action.options.selectedSystem == 'sngbbank') {

        if (action.options.sngbbank.pickedF == 'OneF') {//OneF - screen for 'fail' status, TwoF - url for 'fail' status
          if (!((action.options.sngbbank.failUrl).toString()
            .includes('http'))) {
            if (!((action.options.sngbbank.failUrl).toString()
              .includes('https'))) {

              let currentLocation = window.location.href;
              let regEx = /screenid=[0-9]+/;
              let newStr = 'screenid='.concat(action.options.sngbbank.failUrl, '&');//yes, we make url for redirect here
              let currentFailLocation = currentLocation.replace(regEx, newStr);
              action.options.sngbbank.failUrl = currentFailLocation;

            }
          }
        }

        if (action.options.sngbbank.pickedS == 'OneS') {//OneS - screen for 'success' status, TwoS - url for 'success' status
          if (!((action.options.sngbbank.successUrl).toString()
            .includes('http'))) {
            if (!((action.options.sngbbank.successUrl).toString()
              .includes('https'))) {

              let currentLocation = window.location.href;
              let regEx = /screenid=[0-9]+/;
              let newStr = 'screenid='.concat(action.options.sngbbank.successUrl, '&');
              let currentSuccessLocation = currentLocation.replace(regEx, newStr);
              action.options.sngbbank.successUrl = currentSuccessLocation;

            }
          }
        }

        let arrayAllowed = [
          'phone',
          'email',
          'accountName',
          'amount',
          'description',
          'recState',
          'paymentType',
          'recDay',
          'udf1',
          'udf2',
          'udf3',
          'udf4',
          'udf5',
          'successUrl',
          'failUrl',
          'agreementId',
        ];
        _.each(action.options.sngbbank, (value, key) => {
          if (arrayAllowed.includes(key) && value!=='') {
            if (key === 'amount') {
              Value[key] = parseFloat(value);
            }
            else {
              Value[key] = value;
            }
          }
        });
        const { objid, appid, screenid } = ctx.query;

        Value['applicationId'] = Number(appid);
        // Value['screenId'] = Number(screenid);//does't need
        Value['objectId'] = Number(objid);
        Value['recState'] = '1';//this is recurrent payment every time
        Value['actionName'] = action.options.actionName;
        //Value['selfWebHook'] = 1;//do not understand when it have to be enabled

        let ValueData = {};
        _.each(action.options.value, item => {
          ValueData[item.key] = item.value;
        });
        Value['data'] = ValueData;

        try {
          const data = await ctx.$axios.$post(`/sngb/makepayment`, Value);
          if (data.meta.redirectUrl) {
            if (!(window.location.href = data.meta.redirectUrl)) {
              window.location.replace(data.meta.redirectUrl);
            }
          }
          resolve();
        } catch (e) {
          const {errors} = (e.response || {}).data || e;
          if (errors) {
            let detailErr = '';
            let titleErr = '';
            let delimiter = '';
           _.each(errors, error => {
             detailErr = detailErr + delimiter + error.detail;
             delimiter = '. ';
             titleErr = error.title;
           });
            // ctx.app.$ons.notification.alert({
            //   title: titleErr,
            //   message: detailErr
            // });
            ctx.app.$q.dialog({ title: titleErr, message: detailErr });
          }
          resolve();
        }



      }
      //////////////////

      
      
      
      
      //////////////////sber
      if (action.options.selectedSystem == 'sberbank') {

        if (action.options.sberbank.pickedF == 'OneF') {//OneF - screen for 'fail' status, TwoF - url for 'fail' status
          if (!((action.options.sberbank.failUrl).toString()
            .includes('http'))) {
            if (!((action.options.sberbank.failUrl).toString()
              .includes('https'))) {

              let currentLocation = window.location.href;
              let regEx = /screenid=[0-9]+/;
              let newStr = 'screenid='.concat(action.options.sberbank.failUrl, '&');//yes, we make url for redirect here
              let currentFailLocation = currentLocation.replace(regEx, newStr);
              action.options.sberbank.failUrl = currentFailLocation;

            }
          }
        }

        if (action.options.sberbank.pickedS == 'OneS') {//OneS - screen for 'success' status, TwoS - url for 'success' status
          if (!((action.options.sberbank.successUrl).toString()
            .includes('http'))) {
            if (!((action.options.sberbank.successUrl).toString()
              .includes('https'))) {

              let currentLocation = window.location.href;
              let regEx = /screenid=[0-9]+/;
              let newStr = 'screenid='.concat(action.options.sberbank.successUrl, '&');
              let currentSuccessLocation = currentLocation.replace(regEx, newStr);
              action.options.sberbank.successUrl = currentSuccessLocation;

            }
          }
        }

        let arrayAllowed = [
          'phone',
          'email',
          'accountName',
          'amount',
          'description',
          // 'recState',
          // 'recDay',
          // 'udf1',
          // 'udf2',
          // 'udf3',
          // 'udf4',
          // 'udf5',
          'successUrl',
          'failUrl',
          'selectedTypePayment',
          'agreementId',
          'eventForRec',
        ];
        _.each(action.options.sberbank, (value, key) => {
          if (arrayAllowed.includes(key) && value!=='') {
            if (key === 'amount') {
              Value[key] = parseFloat(value);
            }
            else {
              Value[key] = value;
            }
          }
        });
        const { objid, appid, screenid } = ctx.query;

        Value['applicationId'] = Number(appid);
        // Value['screenId'] = Number(screenid);//does't need
        Value['objectId'] = Number(objid);
        Value['recState'] = '1';//this is recurrent payment every time
        Value['actionName'] = action.options.actionName;
        //Value['selfWebHook'] = 1;//do not understand when it have to be enabled

        let ValueData = {};
        _.each(action.options.value, item => {
          ValueData[item.key] = item.value;
        });
        Value['data'] = ValueData;

        try {
          const data = await ctx.$axios.$post(`/sber/makepayment`, Value);
          if (data.meta.redirectUrl) {
            if (!(window.location.href = data.meta.redirectUrl)) {
              window.location.replace(data.meta.redirectUrl);
            }
          }
          resolve();
        } catch (e) {
          const {errors} = (e.response || {}).data || e;
          if (errors) {
            let detailErr = '';
            let titleErr = '';
            let delimiter = '';
           _.each(errors, error => {
             detailErr = detailErr + delimiter + error.detail;
             delimiter = '. ';
             titleErr = error.title;
           });
            // ctx.app.$ons.notification.alert({
            //   title: titleErr,
            //   message: detailErr
            // });
            ctx.app.$q.dialog({ title: titleErr, message: detailErr });
          }
          resolve();
        }
      }
      //////////////////






      //////////////////stripe
      if (action.options.selectedSystem == 'stripe') {
        if (action.options.stripe.pickedF == 'OneF') {//OneF - screen for 'fail' status, TwoF - url for 'fail' status
          if (!((action.options.stripe.failUrl).toString()
            .includes('http'))) {
            if (!((action.options.stripe.failUrl).toString()
              .includes('https'))) {

              let currentLocation = window.location.href;
              let regEx = /screenid=[0-9]+/;
              let newStr = 'screenid='.concat(action.options.stripe.failUrl, '&');//yes, we make url for redirect here
              let currentFailLocation = currentLocation.replace(regEx, newStr);
              action.options.stripe.failUrl = currentFailLocation;

            }
          }
        }

        if (action.options.stripe.pickedS == 'OneS') {//OneS - screen for 'success' status, TwoS - url for 'success' status
          if (!((action.options.stripe.successUrl).toString()
            .includes('http'))) {
            if (!((action.options.stripe.successUrl).toString()
              .includes('https'))) {

              let currentLocation = window.location.href;
              let regEx = /screenid=[0-9]+/;
              let newStr = 'screenid='.concat(action.options.stripe.successUrl, '&');
              let currentSuccessLocation = currentLocation.replace(regEx, newStr);
              action.options.stripe.successUrl = currentSuccessLocation;

            }
          }
        }

        let arrayAllowed = [
          'accountName',
          'amount',
          'description',
          'successUrl',
          'currency',
          'failUrl',
          'selectedTypePayment'
        ];
        _.each(action.options.stripe, (value, key) => {
          if (arrayAllowed.includes(key) && value!=='') {
            if (key === 'amount') {
              Value[key] = parseFloat(value);
            }
            else {
              Value[key] = value;
            }
          }
        });
        const { objid, appid, screenid } = ctx.query;

        Value['applicationId'] = Number(appid);
        Value['objectId'] = Number(objid);
        Value['actionName'] = action.options.actionName;


        let ValueData = {};
        _.each(action.options.value, item => {
          ValueData[item.key] = item.value;
          Value['data'] = ValueData;
        });


        try {
          const data = await ctx.$axios.$post(`/stripe/makepayment`, Value);
          if (data.meta.redirectUrl) {
            if (!(window.location.href = data.meta.redirectUrl)) {
              window.location.replace(data.meta.redirectUrl);
            }
          }
          resolve();
        } catch (e) {
          const {errors} = (e.response || {}).data || e;
          if (errors) {
            let detailErr = '';
            let titleErr = '';
            let delimiter = '';
            _.each(errors, error => {
              detailErr = detailErr + delimiter + error.detail;
              delimiter = '. ';
              titleErr = error.title;
            });
            // ctx.app.$ons.notification.alert({
            //   title: titleErr,
            //   message: detailErr
            // });
            ctx.app.$q.dialog({ title: titleErr, message: detailErr });
          }
          resolve();
        }
      }
      //////////////////

      
      
      
    });
  };
}

async function signAndGetBankParams(ctx, NKOOptions) {
  let NKO = _.cloneDeep(NKOOptions);
  if (NKO) {
    try {
      if (!ctx.store.state.hashtags.LastEvent.id) {
        //console.log('Last event is not defined');
        // ctx.app.$ons.notification.alert('Last event is not defined', {timeout: 1000});
        ctx.app.$q.dialog({ title: 'Attention', message: 'Last event is not defined' });
        return false;
      }
      const LastEvent = _.cloneDeep(ctx.store.state.hashtags.LastEvent);
      NKO.orderId = LastEvent.id;
      if (isNaN(NKO.orderId)) {
        //console.log('OrderId cannot be converted to integer');
        // ctx.app.$ons.notification.alert('OrderId cannot be converted to integer', {timeout: 1000});
        ctx.app.$q.dialog({ title: 'Attention', message: 'OrderId cannot be converted to integer' });
        return false;
      }
      NKO.amountValue = parseFloat(NKO.amountValue);
      if (isNaN(NKO.amountValue)) {
        //console.log('Amount cannot be converted to float');
        // ctx.app.$ons.notification.alert('Amount cannot be converted to float', {timeout: 1000});
        ctx.app.$q.dialog({ title: 'Attention', message: 'Amount cannot be converted to float' });
        return false;
      }

      NKO.userid = LastEvent.ObjectId || null;
      NKO.eventId = NKO.orderId;
      const resNumber = await ctx.$axios.$get(`/nko/orderid/`+NKO.eventId);

      if (resNumber.meta.orderId50) {
        NKO.orderId = resNumber.meta.orderId50;
      } else {
        // ctx.app.$ons.notification.alert('fail fo generating number', {timeout: 1000});
        ctx.app.$q.dialog({ title: 'Attention', message: 'fail fo generating number' });
        return false;
      }

      let paramsForSign = {  // Отправим на подписывание
        orderId: NKO.orderId,
        amount: NKO.amountValue.toFixed(2),  // it requires to have .00 digits
        //userid: NKO.userid,
        configurationName: NKO.accountName || '',
        clientBackUrl: replaceCurrentUrlWithNewScreen(NKO.successScreen),
        //description: NKO.paymentDescription,
        //email: NKO.email,
        recurrent: (NKO.recurrent === true ? "true" : "false"),
        gis: (NKO.gis === true ? "true" : "false")
      }
      if(NKO.userid){
        paramsForSign.userid = NKO.userid;
      }
      if(NKO.paymentDescription && NKO.paymentDescription.length>0){
        paramsForSign.description = NKO.paymentDescription;
      }
      if(NKO.email && NKO.email.length>0){
        paramsForSign.email = NKO.email;
      }
      //if(NKO.recurrent && NKO.recurrent === true){
      //  paramsForSign.recurrent = NKO.recurrent;
      //}
      //if(NKO.gis && NKO.gis === true){
      //  paramsForSign.gis = NKO.gis;
      //}
      const res = await ctx.$axios.$post(`/nko/sign`, paramsForSign);

      if (res.meta.terminal && res.meta.merchant && res.meta.address && res.meta.sign) {
        NKO.terminal = res.meta.terminal;
        NKO.merchant = res.meta.merchant;
        NKO.address = res.meta.address;
        NKO.sign = res.meta.sign;
      } else {
        //console.log('Not enough data for signing form');
        // ctx.app.$ons.notification.alert('Not enough data for signing form', {timeout: 1000});
        ctx.app.$q.dialog({ title: 'Attention', message: 'Not enough data for signing form' });
        return false;
      }

    } catch (e) {
      //console.log(e);
      // ctx.app.$ons.notification.alert('General error' + e, {timeout: 1000});
      ctx.app.$q.dialog({ title: 'Error', message: e });
      return false;
    }
    //console.log('NKO', NKO);
    return NKO;
  }
}

function submitPaymentForm(NKO) {

  let form = document.createElement("form");
  form.setAttribute("method", "POST");
  form.setAttribute("name", "form");
  form.setAttribute("id", "form");
  form.setAttribute("action", NKO.address + '/main');
  form.setAttribute('enctype', 'application/x-www-form-urlencoded');

  form.setAttribute("target", "_top");

  let iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  //iOS = true;
  if(iOS){
    var element1 = document.createElement("input");
    element1.type="submit";
    form.appendChild(element1);
    var element2 = document.createElement("input");
    element2.type="button";
    element2.onclick=function(){
      form.submit();
    };
    form.appendChild(element2);
    form.target = "_top";
  }

  let attr = function addAttribute(name, value) {
    let hiddenField = document.createElement("input");
    hiddenField.setAttribute("value", value);
    hiddenField.setAttribute("name", name);
    hiddenField.setAttribute("type", 'hidden');
    form.appendChild(hiddenField);
  };

  attr('orderId', NKO.orderId);
  attr('amount', NKO.amountValue.toFixed(2));
if(NKO.userid) {
  attr('userid', NKO.userid);
}
  attr('terminal', NKO.terminal);
  attr('merchant', NKO.merchant);
  attr('clientBackUrl', replaceCurrentUrlWithNewScreen(NKO.successScreen));
if(NKO.paymentDescription && NKO.paymentDescription.length>0) {
  attr('description', NKO.paymentDescription);
}
if(NKO.email && NKO.email.length>0) {
  attr('email', NKO.email);
}
//if(NKO.recurrent && NKO.recurrent === true) {
  attr('recurrent', (NKO.recurrent === true ? "true" : "false"));
//}
//if(NKO.gis && NKO.gis === true) {
  attr('gis', (NKO.gis === true ? "true" : "false"));
//}
  attr('sign', NKO.sign);
  if(iOS) {
    form.setAttribute('enctype', 'application/x-www-form-urlencoded');
    form.enctype = 'application/x-www-form-urlencoded';
  }
  document.body.appendChild(form);
  if(iOS) {
    form.setAttribute('enctype', 'application/x-www-form-urlencoded');
    form.enctype = 'application/x-www-form-urlencoded';
    //console.log('This is ios!!!');
    element2.onclick();
  }
  form.submit();

  // Submission will open NKO bank page
}

function replaceCurrentUrlWithNewScreen(screenId) {
  const loc = location;
  let regex = /screenid=\d+/i;
  let regexResult = /\&result=\d+/i;
  let url = loc.href.replace(regex, 'screenid=' + screenId);
  url = url.replace('&ref=nko', '');
  url = url.replace(regexResult, '');
  url = url + '&ref=nko'; // add reference
  return url;
}
