import localforage from 'localforage'

export const _clearIndexedDB = async () => {
  const dbs = await indexedDB.databases();
  // to prevent blocking deleting by localforage
  localforage._dbInfo.db.close();
  const dbName = "MOBSTEDAPP";
  const req = indexedDB.open(dbName);
  req.onblocked = () => {
    console.warn('Database was blocked while opening')
  };
  req.onerror = () => {
    console.warn('Error during opening database')
  };
  req.onsuccess = (event) => {
    event.target.result.close();
    const deleting = indexedDB.deleteDatabase(dbName);
    deleting.onblocked = () => {
      console.warn('Database was blocked while deleting');
    };
    deleting.onerror = () => {
      console.warn('Error during deleting database')
    };
  };
};

export const _clearCache = async () => {
  const cacheNames = await window.caches.keys();
  return Promise.all(
    cacheNames.map((cacheName) => {
      return caches.delete(cacheName);
    }),
    (e) => {
      console.warn('Error during clearing cache: ', e);
    }
  );
};

export const _clearCookie = () => {
  try {
    document.cookie.split(';').forEach(function(c) {
      document.cookie = c.replace(/^ +/, '').replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
    });
  }
  catch(e) {
    console.warn('Error during clearing cookie: ', e);
  }
};

export const clearSiteData = async () => {
  try {
    localStorage.clear();
    sessionStorage.clear();
    // await _clearIndexedDB();
    await _clearCache();
    //_clearCookie(); for a future
  }
  catch(e) {
    console.warn('Error during clear site data: ', e);
    console.trace();
  }
};
