const IS_DEV = (process.env.NODE_ENV === 'development')
import * as firebase from 'firebase/app'
import 'firebase/analytics'
import 'firebase/messaging'
import Vue from 'vue'

const FBisSupported = firebase.messaging.isSupported();
const firebaseConfig = {
  // apiKey: 'AIzaSyDIVqmlskkb_t-wHyKJkuMfPeY6LfMh7lU',
  // authDomain: '',
  // databaseURL: 'https://mobsted-736b6.firebaseio.com',
  // projectId: 'mobsted-736b6',
  // storageBucket: 'mobsted-736b6.appspot.com',
  // messagingSenderId: '558296893880',
  // appID: '1:558296893880:web:389fd9f4a0a67d43',
  apiKey: "AIzaSyDUbc5u07or0XGhje7M7f9ZKP-QKJvhYWE",
  authDomain: "mobsted-inc.firebaseapp.com",
  databaseURL: "https://mobsted-inc.firebaseio.com",
  projectId: "mobsted-inc",
  storageBucket: "mobsted-inc.appspot.com",
  messagingSenderId: "378961726671",
  appId: "1:378961726671:web:e5bf991fc0ffe01f9ebbba",
  measurementId: "G-TSHGBFTEP1"
};

if (FBisSupported) {
  firebase.initializeApp(firebaseConfig);
  firebase.analytics();
}
const messaging = ('Notification' in window && !IS_DEV && FBisSupported) ? firebase.messaging() : null ;

export default {

  async initFirebaseMessaging({ dispatch }) {
    if (messaging) {
      messaging.usePublicVapidKey('BFlSaejSF-ZebbnDMpkiKs44DbkPTK7O7eGRW7u8ME9nT0bFv0JLu2nWyeS0Ru0w_S_zVqTGi_noPOXcMv8XeXU')

      const scope = `${window.location.origin}/`;
      const registration = await navigator.serviceWorker.getRegistration(scope);

      messaging.useServiceWorker(registration);

      // [START refresh_token]
      // Callback fired if Instance ID token is updated.
      messaging.onTokenRefresh(async () => await dispatch('getTokenAndSendToServer'));
      // [END refresh_token]

      // [START receive_message]
      // Handle incoming messages. Called when:
      // - a message is received while the app has focus
      // - the user clicks on an app notification created by a service worker
      //   `messaging.setBackgroundMessageHandler` handler.
      messaging.onMessage(payload => {
        // TODO: Here we can record the delivery notification
        const notification = payload.data.notification;
        const {
          title, body,
          icon, click_action
        } = (typeof notification === 'object') ? notification : JSON.parse(notification);
        const options = {
          body, icon,
          badge: icon,
          vibrate: [100, 50, 100],
          data: { click_action }
        };
        // TODO: Here we can show UI notification
        registration.showNotification(title, options);
      });
      // [END receive_message]

    }
  },

  /**
   * Get Firebase Messaging token
   * @returns {Promise<string>}
   */
  async getFirebaseMessagingToken() {
    return Promise.resolve(messaging ? await messaging.getToken() : null);
  },

  /**
   * Get Auth Info Application and set in store state
   * @param commit from context
   * @returns {Promise<Object>}
   */
  async setAuthInfo({ commit, rootState }) {
    const { appid: applicationId, glc, objid: idObj, dev } = this.app.context.query;
    const ext = dev === '1' ? { idObj, devmode: dev } : {}
    // const devmode = rootState.devmode ? 1 : 0

    try {
      const appInfo = await this.$axios.$get('/auth/info/application', {
        params: { applicationId, glc, idObj, ...ext }
      });
      if (!appInfo) throw { errors: [{ title: 'Error', detail: 'Application info undefined' }] };

      const applicationAttr = (appInfo.data || [])[0].attributes;
      if (!applicationAttr) throw { errors: [{ title: 'Error', detail: 'Application info undefined' }] };

      // Update Application Info
      commit('setState', appInfo.data[0]);
      return Promise.resolve({ isOk: true });
    } catch (e) {
      const { errors } = (e.response || {}).data || e;
      return Promise.resolve({ isOk: false, errors });
    }
  },

  /**
   * Request permission notification
   * @param dispatch from context
   * @returns {Promise<Promise<boolean>|*>}
   */
  async requestPermissionNotification({ dispatch }) {
    try {
      await messaging.requestPermission();
      return await dispatch('getTokenAndSendToServer');
    } catch (e) {
      console.log('Unable to get permission to notify.');
      return Promise.resolve(false);
    }
  },

  /**
   * Get Firebase Messaging token and save it in the store
   * @param dispatch
   * @returns {Promise<boolean>}
   */
  async getTokenAndSendToServer({ dispatch }) {
    try {
      const token = await dispatch('getFirebaseMessagingToken');
      if (token) {
        await dispatch('setPushEndpoint', token);
        return Promise.resolve(true);
      } else {
        const ERR_MSG = 'No Instance ID token available. Request permission to generate one.';
        throw new Error(ERR_MSG);
      }
    } catch (err) {
      console.log('An error occurred while retrieving token. ', err);
      return Promise.resolve(false);
    }
  },

  async setPushEndpoint({ commit, state }, endpoint) {
    const { objid: objectId, appid: applicationId } = this.app.context.query;
    const currentEndpoint = state.pushEndpoints[objectId] || null;
    if (currentEndpoint !== endpoint) {
      await this.$axios.$post(`/object/${objectId}/push/endpoint`, { applicationId, endpoint });
      commit('setPushEndpoint', { endpoint, objectId });
    }
  }

}
