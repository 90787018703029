const middleware = {}

middleware['checkAppParams'] = require('../middleware/checkAppParams.js')
middleware['checkAppParams'] = middleware['checkAppParams'].default || middleware['checkAppParams']

middleware['checkAuth'] = require('../middleware/checkAuth.js')
middleware['checkAuth'] = middleware['checkAuth'].default || middleware['checkAuth']

middleware['checkScreenId'] = require('../middleware/checkScreenId.js')
middleware['checkScreenId'] = middleware['checkScreenId'].default || middleware['checkScreenId']

export default middleware
