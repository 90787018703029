import _ from 'lodash';

export default {
  component: state => (payload) => {
    if (typeof(payload) === 'string'){
      payload = { uuid: payload }
    }
    let container = payload.container || state.data; // || state.originalData;
    if (container.uuid === payload.uuid) {
      return container
    } else {
      let indexItem = (state.__index || {})[payload.uuid]
      return indexItem ? _.get(container, indexItem.path) : false
    }

    // return container.uuid === payload.uuid ? container : _.get(container, (state.__index || {})[payload.uuid].path);
  },
  componentByName: state => (payload, index) => {
    if (typeof(payload)=='string'){
      payload = {name: payload}
    }
    let container = payload.container || state.data; // || state.originalData;
    let el = false
    if ( !isNaN(parseInt(index)) ){
      let list = Object.values(state.__index).filter(el => el.backendname === payload.name)
      if (list[index]) el = list[index]
    } else {
      el = Object.values(state.__index).find(el => el.backendname === payload.name)
    }

    if (el) {
      return !el.path ? container : _.get(container, el.path)
    } else {
      return null
    }
  },
  originalComponent: state => (uuid) => {
    let find = _.get(state.originalData, (state.__index || {})[uuid].path);

    if (!find) {
      // ** in case of pagination with infinity scrolling,
      // ** there are some diference in `screen` and `originalScreen` structure (see /utils/screen.js/generateLooping)
      // ** therefore if component not exists in `originalScreen` try get it from current screen
      find = _.get(state.data, (state.__index || {})[uuid]);
    }
    return find;
  },
  loopComponent: state => uuid => state.dependencies.loopComponents.find(el=> el.uuid === uuid),
  dependency: state => uuid => state.dependencies.components.find(el=> el.uuid === uuid),
}
