export default ctx => action =>
  new Promise(async resolve => {
    setTimeout(_ => {
      const { to } = action.options;
      const el = document.querySelector(".screen__content");

      if (to == 'top') {
        el.scrollTo(0, 0);
      }
      if (to == 'bottom') {
        el.scrollTo(0, el.scrollHeight);
      }

      resolve();
    }, 0);
  });
