import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const state = () => ({
  isPreload: false,
  originalData: null,
  data: {
    properties: {},
    components: [],
    css: {}
  },
  lastUpdatedFromAPI: null,
  dependencies: {},
  selectedComponent: null,
  __index: {}
});

export default {
  state, actions, mutations, getters, strict: false
}
