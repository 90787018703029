import loadJSAsync from './loadJSAsync';

let defer;
function get(url) {
  if (defer === undefined) {
    defer = new Promise((resolve, reject) => {
      loadJSAsync(url,
        () => resolve(),
        () => reject());
    });
  }
  return defer;
}

const widgetsSDK = {
  get,
};

export default widgetsSDK;