// Load the math.js core
let core = require('mathjs/core');
// Create a new, empty math.js instance
// It will only contain methods `import` and `config`
let mathjs = core.create();
mathjs.import(require('mathjs/lib/type/number'));
mathjs.import(require('mathjs/lib/expression/function/eval'));
mathjs.import(require('mathjs/lib/function/arithmetic'));

import _ from 'lodash';

/**
 * 
 * @param {String} str 
 * @return {String}
 */
export const math = str => {

  const re = /=\(.+?\)=|=\(\)=/g;

  let isParse = false;
  if (!_.isString(str)) {
    str = JSON.stringify(str);
    isParse = true;
  }

  if (str.search(re) != -1) {
    let tempStr = str;
    while (tempStr.search(re) != -1) {
      _.each(tempStr.match(re), key => {
        let temp = key.replace(/=\((.+?)\)=/, '$1');
        let res = 0;
        try {
          res = mathjs.eval(temp);
        } catch (e) {
          console.log(`math.js error - "${e}"`);
          console.log(temp);
        }
        let reg = new RegExp(escapeRegExp(key), 'g');
        tempStr = tempStr.replace(reg, res);
      })
    }
    str = tempStr;
  }

  return isParse ? JSON.parse(str) : str;

}

/**
 * 
 * @param {String} str
 * @returns {String} 
 */
const escapeRegExp = str => str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
