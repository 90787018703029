import _ from 'lodash';
import getters from './getters';

export default {

  updateSelected(state, { uuid, value }) {
    let component = getters.component(state)(uuid);
    component.properties.options = value;
  },

  updateLoopComponents(state, { uuid, components }) {
    let anchorLoopComponent = getters.component(state)(uuid);
    // let { parentUUID, count }  = anchorLoopComponent.anchorLoop;
    if (!anchorLoopComponent.anchorLoop) return;
    let { count }  = anchorLoopComponent.anchorLoop;
    // let loopParent = getters.component(state)(parentUUID);
    let loopParent = getters.component(state)(anchorLoopComponent.parentUUID);

    // ** find start index
    let list = loopParent.components || loopParent.items;
    let startIndex = list.findIndex(el => el.uuid === uuid)
    // **

    //loopParent.components.splice(++startIndex, count, ...components);
    list.splice(++startIndex, count, ...components);
  },

  setScreen(state, payload) {
    state.data = payload
  },

  // ** Create flat list, contains UUID of all screen components
  // ** this index uses for fast getting component by his UUID
  // **     (see: /store/screen/getters.js[component and originalComponent],
  // **      /utils/screen.js[getComponentByUUID])
  createScreenIndex(state, payload){

    let index = {};
    let path = state.__index[payload.uuid]? state.__index[payload.uuid].path : '';

    const indexSubComponents = (component, path) => {

      if (!component.properties) {
        return
      }
      index[component.uuid] = { path, backendname: component.properties.backendname };
      if (component.components) {
        for (let [i, el] of Object.entries(component.components)) {
          indexSubComponents(el, (path? path+'.':'') + 'components.' + i)
        }
      }
      if (component.items) {
        for (let [i, el] of Object.entries(component.items)) {
          indexSubComponents(el, (path? path+'.':'') + 'items.' + i)
        }
      }
    };
    indexSubComponents(payload, path);

    state.__index = Object.freeze(index)
  },

  setOriginalData(state, payload) {
    state.originalData = Object.freeze(payload);
  },

  setValue(state, { uuid, value }) {
    let component = getters.component(state)(uuid);
    component.properties.value = value;
  },

  setDependencies(state, payload) {
    state.dependencies = state.dependencies || {};
    if (payload.components) state.dependencies.components = Object.freeze(payload.components);
    if (payload.loopComponents) state.dependencies.loopComponents = Object.freeze(payload.loopComponents);
    //state.dependencies = Object.freeze(payload);
  },

  updateComponents(state, payload) {
    for (let item of payload) {
      if (!_.isEmpty(item.paths)) {
        for (let path of item.paths) {
          let component = getters.component(state)(item.uuid);
          if (component) _.set(component, path.path, path.value);
        }
        // item.paths.forEach(path => {
        //   let component = getters.component(state)(item.uuid);
        //   if (component) _.set(component, path.path, path.value);
        // })
      }
    }
    // _.each(payload, item => {

    // });
  },

  setLastUpdatedFromAPI(state, payload) {
    state.lastUpdatedFromAPI = payload
  },

  setIsPreload(state, payload) {
    state.isPreload = payload;
  },

  setSelectedComponent(state, uuid) {
    state.selectedComponent = uuid;
  },
}
