import _ from 'lodash';

export default ctx => action =>
  new Promise(async resolve => {
    let objectColumns = {};

    _.each(action.options.value, item => {
      objectColumns[item.key] = item.value;
    });

    if (!_.isEmpty(objectColumns)) {
      const { objid, appid: applicationId } = ctx.query;

      try {
        const { data: { attributes: data } } = await ctx.$axios.$put(`object/${objid}`, null, {
          params: {
            applicationId,
            ...objectColumns
          }
        });
        if (!data) throw new Error();
        ctx.store.commit('hashtags/mergeStateByKey', { key: 'Object', data });
        ctx.store.dispatch('screen/updateComponents', 'Object');
      } catch(e) {
        // ctx.app.$ons.notification.toast('Update Object failed', { timeout: 1000 });
        ctx.app.$q.dialog({ title: 'Error', message: 'Update Object failed' });
      }

    }

    resolve();
  });
