import Vue from 'vue'

const DevmodeController = Object.assign(Vue.observable({ isActive: false }), {
  debug: true,
  $store: null,
  commandHandlerObject: null,
  forceDisable: false,

  setCommandHandler: function (obj) {
    this.commandHandlerObject = obj
  },

  onRecieve: function (evt) {
    if (typeof evt !== 'object' || evt.data.type !== 'devmode' || evt.source === window) return

    // ** auto enable dev mode **
    if (!DevmodeController.$store.state.devmode && !DevmodeController.forceDisable) {
      DevmodeController.$store.commit('setDevmode', true)
    }

    if (this.debug) console.log(' RECIEVED DEV MESSAGE (mu) ', evt.data)

    if (evt.data.action) {
      if (evt.data.livemode !== undefined) {
        DevmodeController.forceDisable = evt.data.livemode
        DevmodeController.isActive = DevmodeController.$store.state.devmode && !DevmodeController.forceDisable
      }

      const methodName = `on${evt.data.action.charAt(0).toUpperCase() + evt.data.action.slice(1)}`
      if (DevmodeController.commandHandlerObject) {
        if (typeof DevmodeController.commandHandlerObject[methodName] === 'function') {
          DevmodeController.commandHandlerObject[methodName](evt.data)
        }
      }
    }
  },

  send: function (data) {
    if (this.debug) console.log(' SEND from mu ', data)
    const type = 'devmode'
    parent.postMessage(Object.assign({ type }, data), '*')
  },

  init: function (screenid) {
    window.addEventListener("message", this.onRecieve)
    // if (screenid) this.send({ action: 'ready', id: screenid || this.$store.state.screen.id })
  }
})

export default ({ store, route }) => {
  const { appid, objid, screenid } = route.query

  DevmodeController.$store = store
  DevmodeController.debug = false //process.env.NODE_ENV === 'development'
  Vue.prototype.$devmode = DevmodeController
  store.$devmode = DevmodeController

  if (document.readyState === 'complete') {
    DevmodeController.init(screenid)
  } else {
    document.onreadystatechange = () => {
      if (document.readyState === 'complete') {
        DevmodeController.init(screenid)
      }
    }
  }
}
