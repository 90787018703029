import isString from 'lodash/isString';
import isNil from 'lodash/isNil';

/**
 * Задержка
 *
 * @param {number} ms
 * @returns {Promise<void>}
 */
export const delay = ms => (
  new Promise(resolve => setTimeout(resolve, ms))
);

export const sessionStorage = {
  setItem(key, payload) {
    const data = isString(payload) ? payload : JSON.stringify(payload);
    window.sessionStorage.setItem(key, data);
  },
  getItem(key) {
    return JSON.parse(window.sessionStorage.getItem(key));
  },
  removeItem(key) {
    window.sessionStorage.removeItem(key);
  },
  assignItem(key, payload) {
    const res = this.getItem(key) || {};
    const assignRes = Object.assign(res, payload);
    this.setItem(key, assignRes);
  }
};

export const prepateJsonForList = (json, tableFields) => {
  const typeConverter = {
    to_integer(data) {
      return isNil(data) ? null : Number(data)
    },
    to_text(data) {
      return isNil(data) ? null : data.toString()
    },
    to_varchar(data) {
      return isNil(data) ? null : data.toString()
    },
    to_boolean(data) {
      return (isNil(data) || data === 'null') ? null : data.toString()
    }
  }

  let prepareJson = {}
  for (const field of tableFields) {
    const funcName = `to_${field.type.replace(/ *\([^)]*\) */g, "")}`

    if (json.hasOwnProperty(field.name))
      prepareJson[field.name] = typeof typeConverter[funcName] === 'function' ?
        typeConverter[funcName](json[field.name]) :
        json[field.name]
  }

  return prepareJson
}
