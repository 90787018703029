import Vue from 'vue';
// const moment = require('moment');
import formatDate from 'date-fns/format';

Vue.filter('shortUUID', value => {
  if (!value) return '';
  return value.match(/(.+?)(?=-)/)[0];
});
Vue.filter('formatDate', (value, format) => {
  if (!value) return '';
  // if (!format) format = 'HH:mm, DD.MM.YYYY';
  if (!format) format = 'HH:mm, dd.MM.yyyy';
  // return moment(value).format(format);
  return formatDate(value, format);
});
