import _ from 'lodash';

export default ctx => async action =>
  new Promise(async resolve => {
    if (action.options.isShowPreloader) ctx.store.commit('screen/setIsPreload', true);
    const operationsIds = action.options.value;
    const { objid, appid, screenid } = ctx.query;
    let operationsList = [];

    try {
      const { data } = await ctx.$axios.$get('/operations', {
        params: {
          page: 1,
          pageSize: operationsIds.length,
          ids: JSON.stringify(operationsIds)
        },
      });
      if (!data) throw {errors: [{title: 'Error', detail: 'Failed get operations list'}]};
      operationsList = data;
    } catch(e) {
      if (action.options.isShowPreloader) ctx.store.commit('screen/setIsPreload', false);
      const { errors } = (e.response || {}).data || e;
      if (errors) {
        const code = parseInt(e.response && e.response.status);
        if (code !== 403) {
          _.each(errors, error => {
            ctx.app.$q.dialog({ title: error.title, message: error.detail });
          });
        }
      }
      resolve(false);
    }

    const promises = _.map(operationsIds, operationId => {
      const findOperation = _.find(operationsList, operation => operation.id == operationId);
      if (findOperation) {
        let ExtraParams = {};
        const operationHashtags = findOperation.attributes.Hashtags;
        _.each(operationHashtags, oh => {
          const path = _.trim(oh, '#').replace(/\:/g, '.');
          const value = _.get(ctx.store.state.hashtags, path);
          _.setWith(ExtraParams, path, value, Object);
        });
        return new Promise(async resolve1 => {
          let result;
          try {
            const { data } = await ctx.$axios.$post(`/operations/execute`, {
              operationId,
              applicationId: Number(appid),
              screenId: Number(screenid),
              objectId: Number(objid),
              ExtraParams
            });
            result = data;
          } catch(e) {
            result = null;
          }
          resolve1({ [findOperation.attributes.Name]: result });
        });
      }
    });

    const promiesAll = await Promise.all(promises);
    const data = _.reduce(promiesAll, function(memo, v, i) {
      let key = _.keys(v)[0];
      memo[key] = v[key];
      return memo
    }, {});
    const key = 'Operation';

    ctx.store.commit('hashtags/mergeStateByKey', { key, data });
    ctx.store.dispatch('screen/updateComponents', key);

    if (action.options.isShowPreloader) ctx.store.commit('screen/setIsPreload', false);


    if (!action.result) {
      resolve(true);
      return true;
    }

    // TODO:
    let actions;
    try {
      actions = action.result.done;
    } catch (err) {
      actions = action.result.fail;
    }

    resolve(actions);
  });
