import Vue from 'vue';
import { Quasar, Ripple, Platform, Dialog } from 'quasar';

import Plugin from '@quasar/quasar-ui-qmediaplayer'
import '@quasar/quasar-ui-qmediaplayer/dist/index.css'

Vue.use(Quasar, {
  directives: {
    Ripple
  },
  plugins: {
    Dialog
  }
})

Vue.use(Plugin)

export default (ctx, inject) => {
  const { os } = ctx.query;
  if (['ios', 'android'].includes(os)) {
    Object.assign(Platform.is, {android: undefined, ios: undefined});
    Platform.is[os] = true;
  }

  let origDialog = Vue.prototype.$q.dialog;
  Vue.prototype.$q.dialog = (_ref) => {
    _ref.class = (_ref.class || '') + (Platform.is.ios ? 'ios' : 'android')
    return origDialog(_ref)
  }

  inject('q', Vue.prototype.$q);
}
