import _ from 'lodash';
import MakeEventBus from '../MakeEventBus';

const EventBus = MakeEventBus('widgets');
export default ctx => {
  return action => {
    return new Promise(async resolve => {
      let Value = {};
      let fileControls = []; // имена элементов которые будут обрабатываться
      _.each(action.options.value, item => {
        Value[item.key] = item.value;
      });
      let backendnameHashtagsValues = _.cloneDeep(ctx.store.state.hashtags.Backendname);
      let files = [];
      _.each(action.options.files, (eventFileComponent) => { // переберем все прикрепленные компоненты файлов
        _.each(backendnameHashtagsValues, (componentValueWithFiles, componentName) => { // переберем все элементы
          if (eventFileComponent.key === componentName) { // если имя элемента совпадает с файловой переменной
            _.each(componentValueWithFiles, (fileItem) => { // прееберем все прикрепленные файлы
              files.push({name: eventFileComponent.value, id: fileItem.id});
            });
            backendnameHashtagsValues[componentName] = []; // для обнуления файлов в store
            fileControls.push(componentName); // для дальнейшего обнуления данных в самих компонентах
          }
        });
      });
      await ctx.store.dispatch('createEvent', {
        ActionName: action.options.actionName,
        Value,
        Files: JSON.stringify(files)
      });
      // TODO как то реагировать если данные не были сохранены
      //оповестим у какого элемента нужно очистить файлы
      EventBus.$emit('global:removeUploadedFilesFromControls', fileControls);
      // TODO: обновляются все хэштеги backendname, а нужно только файловые
      ctx.store.commit('hashtags/mergeStateByKey', {
        key: 'Backendname',
        data: backendnameHashtagsValues
      });
      resolve();
    });
  };
}
