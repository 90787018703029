import _ from 'lodash';

export default ctx => action =>
  new Promise(resolve => {
    const data = _.reduce(action.options.value, (res, item) => {
      res[item.key] = item.value;
      return res;
    }, {});
    let key = 'Variable';
    ctx.store.commit('hashtags/mergeStateByKey', { key, data });

    let vars = Object.keys(data);
    if (vars.length == 1) {
      key += ':' + vars[0];
    }

    ctx.store.dispatch('screen/updateComponents', key);
    resolve();
  });
