<template>
  <div class="screen">
    <div v-if="$nuxt.isOffline && isIOS" class="text-center">
      <div class="status-code">{{ offline.status }}</div>
      <p class="is-color-grey offline-message">{{ offline.message }}</p>
      <p class="is-color-grey offline-message">{{ offline.stepsTitle }}</p>
      <ul class="is-text-left">
        <li v-for="(step, ind) in offline.steps" :key="ind" class="is-color-grey">{{ step }}</li>
      </ul>
    </div>
    <div v-else class="text-center">
      <div class="status-code">{{ statusCode }}</div>
      <h3>{{ title }}</h3>
      <div class="inner-content">
        <p class="is-color-grey">{{ message }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import DevmodePlugin from '../plugins/devmodeController'

export default {
  props: [ 'error' ],
  head() {
    return {
      title: this.pageTitle
    }
  },
  data: () => ({
    defaults: {
      500: {
        title: 'Something went wrong',
        message: 'Our team has been notified and is working on the issue'
      },
      404: {
        title: 'Page not found',
        message: 'We couldn\'t locate the page you are looking for'
      },
      403: {
        title: 'Access Denied',
        message: ''
      },
      400: {
        title: 'Bad Request',
        message: 'Something went wrong'
      }
    },
    offline: {
      status: 'Offline',
      message: 'Your iOS device has an Offline Error.',
      stepsTitle: 'Please follow:',
      steps: [
        'swipe up the app out of “active apps” list',
        'go to Settings > Safari > Clear History & Web data',
        'check your Internet connection',
        're-start the app',
      ]
    }
  }),
  computed: {
    devmode: vm => vm.$store.state.devmode,
    statusCode: vm => vm.error.statusCode || 500,
    pageTitle: vm => vm.statusCode + ' ' + (vm.error.title || vm.defaults[vm.statusCode].title),
    title: vm => vm.error.title || vm.defaults[vm.statusCode].title,
    message: vm => vm.error.message || vm.defaults[vm.statusCode].message,
    // isIOS: vm => vm.$ons.platform.isIOS(),
    isIOS: vm => vm.$q.platform.is.ios,
  },

  created () {
    if (!this.$devmode) DevmodePlugin( { store: this.$store, route: this.$route })
    this.$devmode.send({ action: 'error' })
    // debugger
    // this.$root.$emit('devmode:error', this.error)
  }
}
</script>

<style lang="scss" scoped>
  h3 {
    font-weight: bold;
    font-size: 24px;
    line-height: unset;
  }
  .screen {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .inner-content {
      max-width: unset;
      padding: 0 20px;
      white-space: pre-wrap;
    }
  }
  .offline-message,
  .is-text-left {
    text-align: left;
  }

  .offline-message {
    padding-left: 10px;
  }

  .status-code {
    font-size: 80px;
    font-weight: bold;
    opacity: .085;

    p {
      margin: 0;
    }
  }
</style>
