import _ from 'lodash';

export default ctx => action =>
  new Promise(resolve => {

    // let component = ctx.store.getters['screen/component'](action.options.componentUUID);
    let component = ctx.store.getters['screen/componentByName'](action.options.componentName);
    if (component){
      component.properties[action.options.propertyName] = action.options.propertyValue;
    }

    if (action.options.propertyName == 'value') {
      let key = 'Backendname';

      ctx.store.commit('hashtags/mergeStateByKey', { key, data: { [component.properties.backendname]: action.options.propertyValue } });
      ctx.store.dispatch('screen/updateComponents', key);
    }

    resolve();
  });
