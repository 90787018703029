export default {
  setState(state, payload) {
    state.id = Number(payload.id) || null;
    state.isEnabled = (payload.attributes.Enabled == 1);
    state.isValidAuthConfirmCode = payload.attributes.auth_code_is_valid;
    state.homeScreen = payload.attributes.home_screen || null;
    state.phone = payload.attributes.Phone || null;
    state.email = payload.attributes.Email || null;
    state.errors = payload.attributes.errors || null;
  }
}
