import _ from 'lodash';
import Vue from 'vue';

const customizerMerge = (objValue, srcValue) => {
  if (_.isArray(objValue)) return srcValue;
}

const customizerMergeAppendArray = (objValue, srcValue) => {
  if (_.isArray(objValue)) return objValue.concat(srcValue);
}

export const state = () => ({
  Tenant: {},
  Application: {},
  Screen: {},
  Object: {},
  Variable: {},
  ObjectsFilter: {},
  EventsFilter: {},
  Payment: {},
  Operation: {},
  Backendname: {},
  LastEvent: {},
  Route: {},
  List: {},
  ListsFilter: {},
  Tax: {},
});

export const mutations = {
  setStateByKey(state, payload) {
    let s = state;
    _.set(s, payload.key, payload.data);
    state = s;
    // state[payload.key] = payload.data;
  },

  unsetStateByKey(state, payload) {
    if (payload.data) {
      for (let i of payload.data) {
        Vue.delete(state[payload.key], i);
      }
    } else {
      Vue.delete(state, payload.key);
    }
  },

  /**
   * Merge hashtags by key
   * @param state
   * @param payload {Object} key, data
   */
  mergeStateByKey(state, payload) {

    if (state[payload.key] == undefined) {
      state[payload.key] = {}
    }

    // ** when store is empty (ex.: after application data cleaning), setting `hashtags.Backendname.someName = value` not causes reactivity
    // ** in this case we need initialize undefined values in store with empty value trough `Vue.set`
    if ( typeof(payload.data) === 'object' ){
      for (let i in payload.data) {
        if ( state[payload.key][i] === undefined ) {
          Vue.set(state[payload.key], i, '');
        }
      }
    }
    // **

    if (payload._mergeType === 'append') {
      state[payload.key] = _.mergeWith(state[payload.key], payload.data, customizerMergeAppendArray);
    } else {
      state[payload.key] = _.mergeWith(state[payload.key], payload.data, customizerMerge);
    }
  },

  /**
   * Merge all hashtags
   * @param state
   * @param payload {Object}
   */
  mergeState(state, payload) {
    state = _.mergeWith(state, payload, customizerMerge);
  }
};


export const getters = {
  value: (state) => (path) => _.get(state, path, undefined),
  componentValue: (state) => (path) => _.get(state.Backendname, path)
};

export const actions = {

};

export const strict = false;
