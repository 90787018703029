import actions from './actions';
import mutations from './mutations';

const state = () => ({
  id: null,
  isEnabled: null,
  isValidAuthConfirmCode: null,
  homeScreen: null,
  email: null,
  phone: null,
  errors: null,
});

export default {
  state, actions, mutations, strict: false
}
