import { Platform } from 'quasar';

window.onNuxtReady(app => {
  if (process.env.NODE_ENV === 'production') {
    if (navigator.serviceWorker) {

    // } else if (app.$options.$ons.platform.isIOS() && window.applicationCache) {
    } else if (Platform.is.ios && window.applicationCache) {
      // register appcache code
      const iframe = document.createElement('iframe');
      iframe.style.display = "none";
      iframe.src = '/_nuxt/appcache.html';
      document.body.appendChild(iframe);
    }
  }
});
