import jwtDecode from 'jwt-decode';
import _ from 'lodash';
import { sessionStorage } from '../utils/utils';

const SHARED_DATA_ENDPOINT = 'shared-auth';
const IS_DEV = (process.env.NODE_ENV === 'development');

/**
 * Плагин для vuex, при каждом измении состояния, будет записывать изменения
 * в локальное хранилище, работает только в браузере, проверка process.browser
 * @param {object} store
 */
const persistPlugin = store => {
  if (process.browser) {
    store.subscribe(_.debounce(async (mutations, state) => {
      const { appid, objid } = store.app.context.query;
      const APP_OBJ_STORE_NAME = `appid_${appid}_objid_${objid}`;
      await store.$idb.state.setItem(APP_OBJ_STORE_NAME, Object.freeze(state));

      if (store.$devmode.isActive)
        store.$devmode.send({ action: 'setHashtagsData', payload: Object.freeze(state.hashtags) })
    }, 750, { trailing: true }));
  }
};
export const plugins = [persistPlugin];

export const state = () => ({
  lastUpdateDate: null, // дата последнего обневления данных по АПИ
  screens: {
    data: [],
  },
  confirmationScreenName: null,
  auth: null,
  from: null,
  devmode: false,
  prefetchImages: [],
  renderHashtags: true,
  displayOncomponentLabels: true,
  itHasGoauth: false,
  itHasKey: '',
  pwa: {}
})

export const getters = {
  accessToken: state => state.auth && state.auth.access_token || null,
  refreshToken: state => state.auth && state.auth.refresh_token || null,
  returnVisitorTimeout: state => state.auth && state.auth.returnVisitorTimeout || 0,
  renderHashtags: state => state.devmode && state.renderHashtags
};

export const actions = {
  async nuxtServerInit({dispatch}) {
    await dispatch('fetchAuth');
  },

  /**
   * Updating authorization data (state, localforage, cache)
   * @param {Object} context
   * @param {Function} context.commit
   * @param {Object} auth
   */
  async updateAuth({ commit, state }, auth) {
    commit('update', { key: 'auth', data: auth });

    const { appid, objid } = this.app.context.query;
    let authList = await this.$idb.authList.getItem('authList') || {};

    if (auth) {
      let { returnVisitorTimeout } = state.application;
      returnVisitorTimeout = !!returnVisitorTimeout ? Math.round(new Date().setUTCSeconds(returnVisitorTimeout)/1000) : 0

      if (!authList[appid]) authList[appid] = [];
      let findAuth = _.find(authList[appid], v => v.objid == objid);
      if (findAuth && findAuth.data) {
        findAuth.data = {
          ...auth,
          returnVisitorTimeout
        };
      } else {
        authList[appid].push({
          objid, data: {
            ...auth,
            returnVisitorTimeout
          }
        });
      }
    } else {
      const index = _.findIndex(authList[appid], v => v.objid == objid);
      if (index != -1) authList[appid].splice(index, 1);
    }

    // Setting in cache (workaround for standalone mode safari iOS)
    if (!IS_DEV)
      try { await this.$axios.$post(SHARED_DATA_ENDPOINT, authList) } catch (e) {}

    // Setting in IndexedDB
    await this.$idb.authList.setItem('authList', authList);
  },

  /**
   * Getting authorization data
   * @param {Object} context
   * @param {Function} context.dispatch
   */
  async fetchAuth({ state, commit }) {
    const { appid, objid } = this.app.context.query;
    const { isIdentityEveryLogin } = state.application;

    if (isIdentityEveryLogin) {
      const keyStartSession = `login_${appid}_${objid}`;
      const isLogin = sessionStorage.getItem(keyStartSession);
      if (!isLogin) return false;
    }

    // Getting from IndexedDB
    let authList = await this.$idb.authList.getItem('authList');
    // Getting from cache (workaround for standalone mode safari iOS)
    if (!authList && !IS_DEV)
      try { authList = await this.$axios.$get(SHARED_DATA_ENDPOINT); } catch(e) {}

    if (authList && authList[appid]) {
      if (objid) {
        const findAuth = _.find(authList[appid], v => v.objid == objid);
        const auth = (findAuth || {}).data || null;
        if (auth) commit('update', { key: 'auth', data: auth }); //await dispatch('updateAuth', auth);
      } else {
        // TODO: Check for quantity and if more than one redirect to the object selection screen
        const auth = (authList[appid][0] || {}).data;
        const { objid } = authList[appid][0] || {};
        if (auth && objid) {
          commit('update', { key: 'auth', data: auth });
          this.app.context.redirect(`/${appid}`, { ...this.app.context.query, objid });
        }
      }
    }
  },

  /**
   * Check Tokens not expired (accessToken, refreshToken)
   * @param dispatch
   * @param typeToken - default 'accessToken'
   * @returns {Promise<*|boolean>}
   */
  async isTokenNotExpired({dispatch}, typeToken) {
    const expDate = await dispatch('getTokenExpirationDate', typeToken);
    return (expDate && expDate.getTime() > Date.now());
  },

  /**
   * Сheck accessToken, refreshToken
   * Refresh tokens if accessToken expired
   * @param dispatch
   * @param state
   * @returns {Promise<boolean>}
   */
  async checkToken({dispatch, state}) {
    await dispatch('fetchAuth');
    if (!!state.auth) {
      if (await dispatch('isTokenNotExpired'))
        return true;

      if (await dispatch('isTokenNotExpired', 'refreshToken'))
        if (await dispatch('refreshToken'))
          return true;

      return false;
    }
    return false;
  },

  /**
   *
   * @param dispatch
   * @param getters
   * @param state
   * @returns {Promise<*>}
   */
  async getAccess({ dispatch, getters, state }) {

    const { appid: applicationId, objid: objectId } = this.app.context.query;

    if (!state.application.firstAuthenticationScreen) {
      const res = await dispatch('authorize', { fields: {applicationId, objectId} });
      if ( ((res || {}).meta || {}).screenName ) {
        return this.app.context.redirect('/auth/signin', {...this.app.context.query});
      } else {
        return res;
      }
    }

    return this.app.context.redirect('/auth/signin', {...this.app.context.query});
  },

  /**
   * Authorization
   * @param {Object} context
   * @param {Function} context.dispatch
   * @param {Object} data
   * @param {Object} data.fields
   */
  async authorize({ dispatch, commit, state }, { fields = {} }) {
    try {
      const { appid: applicationId, objid: objectId } = this.app.context.query;
      const keyAuthChain = `authChain_${applicationId}_${objectId}`;
      const keyCodeSent = `codeSent_${applicationId}_${objectId}`;
      const authChain = sessionStorage.getItem(keyAuthChain);
      const res = await this.$axios.$get('/mu/authorize', {
        params: {
          applicationId,
          objectId,
          ..._.omit(this.app.context.query, ['appid', 'objid']),
          ...authChain,
          ...fields,
        },
        uni: Date.now() // TODO: safari cache not fix
      });
      const screenName = ((res || {}).meta || {}).screenName;
      if (screenName) {
        commit('update', { key: 'confirmationScreenName', data: screenName });
        return Promise.resolve(res);
      }
      if ( !((res || {}).data || {}).attributes ) {
        throw {
          errors: [
            {
              title: 'Error',
              detail: '[authorize] API method failed',
              error: {
                statusCode: 500,
                message: 'Our team has been notified and is working on the issue'
              }
            }
          ]
        };
      }
      const auth = {
        access_token: res.data.attributes.accessToken,
        refresh_token: res.data.attributes.refreshToken,
      }
      if (auth && auth.access_token) {
        await dispatch('updateAuth', auth);
        commit('update', { key: 'confirmationScreenName', data: null });
        commit('application/setStateByKey', { key: 'firstAuthenticationScreen', payload: null });
        sessionStorage.removeItem(keyAuthChain);
        sessionStorage.removeItem(keyCodeSent);

        const { isIdentityEveryLogin } = state.application;
        if (isIdentityEveryLogin) {
          const keyStartSession = `login_${applicationId}_${objectId}`;
          sessionStorage.setItem(keyStartSession, Date.now());
        }

        return Promise.resolve({ isOk: true });
      } else {
        throw res;
      }
    } catch (e) {
      const { errors } = (e.response || {}).data || e;
      _.each(errors, error => {
        let options = {
          title: error.title || 'Error',
          message: error.detail || 'Undefined error',
        };
        if (error.error) options.callback = () => this.app.context.error(error.error);
        // this.$ons.notification.alert(options);
        this.$q.dialog(options);
      });
    }
  },

  /**
   * Registration with authentication (Emai/Phone [Set PIN])
   * @param {Object} context
   * @param {Object} context.state
   * @param {Object} payload
   * @returns {Promise<axios>}
   */
  async registration({ state }, payload) {
    const { appid: applicationId } = this.app.context.query;
    const body = {
      applicationId,
      ...payload
    };
    return await this.$axios.$post('/mu/reg', body);
  },

  redirectAfterRegistration(ctx, objid) {
    const { appid, screenid } = this.app.context.query;
    this.app.context.redirect(`/${appid}`, { appid, screenid, objid });
  },


  /**
   *
   * @param {Object} context
   * @param {Object} context.state
   * @param {Object} payload
   * @returns {Promise<axios>}
   */
  async applicationoauth({ dispatch, commit, state }, { paramsForSign = {} }) {
    const res = await this.$axios.$post(`/auth/applicationoauth`, paramsForSign);
    // console.log('res', res);
    if (res) {
      const { Key1 } = res;
      // console.log('Key1', Key1);
      if (Key1) {
        commit('update', { key: 'itHasGoauth', data: true });
        commit('update', { key: 'itHasKey', data: Key1 });
      }
    } else {
      console.log('Not executed get key');
    }
    return res;


  },



  /**
   * Registration without authentication
   * @param {Object} context
   * @param {Object} context.state
   * @param {Object} payload
   * @returns {Promise<axios>}
   */
  async register() {
    const { appid: applicationId } = this.app.context.query;
    try {
      let bodyFormData = new FormData();
      bodyFormData.set('applicationId', applicationId);
      const res = await this.$axios.$post('/mu/register', bodyFormData, {
        headers: {'Content-Type': 'multipart/form-data'}
      });
      if (res.data && res.data.attributes && res.data.attributes.objectId) {
        return Promise.resolve(
          // TODO: передать все query
          window.location = `/${applicationId}?appid=${applicationId}&objid=${res.data.attributes.objectId}`
        );
        // return this.app.context.redirect('/', {
        //   appid: applicationId,
        //   screenid: this.app.context.query.screenid,
        //   objid: res.data.attributes.objectId
        // })
      } else {
        throw res;
      }
    } catch (e) {
      console.log(e.response);
      return this.app.context.error();
    }
  },

  async logout({ dispatch, state }) {
    await dispatch('updateAuth', null);
    if (state.application.authType)
      this.app.context.redirect('/auth/signin', {...this.app.context.query});
    else
      location.reload();
  },

  /**
   * Create Event
   * @param store
   * @param data
   * @returns {Promise<void>}
   */
  async createEvent(ctx, data) {
    let formData = new FormData();
    formData.set('ApplicationId', this.app.context.query.appid);
    formData.set('ObjectId', this.app.context.query.objid);
    formData.set('ScreenId', this.app.context.query.screenid);
    _.each(data, (value, key) => {
      if (key === 'Value')
        value = JSON.stringify(value);

      formData.set(key, value);
    })
    try {
      const res = await this.$axios.$post('/events', formData, {
        headers: {'Content-Type': 'multipart/form-data'}
      });

      const resEvent = ((res.data || [])[0]) || {};
      if (!resEvent.id || !resEvent.attributes) throw new Error('Failed create event');

      const data = {
        id: Number(resEvent.id),
        ...resEvent.attributes
      };
      const key = 'LastEvent'

      ctx.commit('hashtags/setStateByKey', { key, data });
      ctx.dispatch('screen/updateComponents', key);
    } catch (e) {
      console.error(e);
    }
  },

  /**
   * Create Event
   * @param store
   * @param data
   * @returns {Promise<void>}
   */
  async updateEvent(ctx, data) {
    if (!data.id) { // если нет id события то нехер делать
      console.error('Event id is not defined');
      throw 'Event id is not defined';
    }
    data['ApplicationId'] = this.app.context.query.appid;  // хуево но выше также сделано
    let formData = data.id + '?';
    _.each(data, (value, key) => {
      if (key === 'Value') {
        value = JSON.stringify(value);
      }
      if (key !== 'id') // пропустим id так как он в качестве путевого параметра встал выше
        formData = formData + '&' + key + '=' + value;
    });
    try {
      const res = await this.$axios.$put('/events/' + formData);
      const resEvent = ((res.data || [])[0]) || {};
      if (!resEvent.id || !resEvent.attributes) throw new Error('Failed create event');

      const data = {
        id: Number(resEvent.id),
        ...resEvent.attributes
      };
      const key = 'LastEvent'

      ctx.commit('hashtags/setStateByKey', { key, data });
      ctx.dispatch('screen/updateComponents', key);
    } catch (e) {
      console.error(e);
    }
  },

  /**
   * Update Object
   * @param store
   * @param data
   * @returns {Promise<void>}
   */
  async updateObject(store, data) {
  },

  /**
   * Refresh token
   * @param {Object} store
   * @param {Function} store.dispatch
   * @returns {Promise<void>}
   */
  async refreshToken({ dispatch, getters, state }) {
    try {
      const auth = await this.$axios.$get('/mu/refresh', {
        params: {
          refresh_token: getters.refreshToken,
          uni: Date.now()
        }
      });
      if ((((auth || {}).data || {}).attributes || {}).refreshToken) {
        await dispatch('updateAuth', {
          access_token: auth.data.attributes.accessToken,
          refresh_token: auth.data.attributes.refreshToken,
        });
        return Promise.resolve(auth);
      } else {
        throw new Error();
      }
    } catch (error) {
      return Promise.resolve(false);
    }
  },

  /**
   * Getting date expired token
   * @param typeToken {string} accessToken OR refreshToken
   */
  getTokenExpirationDate({getters}, typeToken = 'accessToken') {
    try {
      const { returnVisitorTimeout } = getters;
      const encodedToken = getters[typeToken];
      if (!encodedToken) {
        return Promise.resolve(false);
      }
      const {exp} = jwtDecode(encodedToken);
      if (!exp) {
        return Promise.resolve(false);
      }
      let expDate = new Date(0);
      expDate.setUTCSeconds(exp);

      if (returnVisitorTimeout > 0) {
        let returnVisitorExpDate = new Date(0)
        returnVisitorExpDate.setUTCSeconds(returnVisitorTimeout)

        if (expDate.getTime() > returnVisitorExpDate.getTime())
          expDate = returnVisitorExpDate;
      }

      return Promise.resolve(expDate);
    } catch (e) {
      return Promise.resolve(false);
    }
  },

  async getAllScreens({commit, rootState}) {
    try {
      const headers = (rootState.screens || {}).lastModified ?
        {'If-Modified-Since': rootState.screens.lastModified} : {};
      const {appid: ApplicationId} = this.app.context.query;
      const res = await this.$axios.get('screen', {
        headers,
        params: {
          ApplicationId,
          page: 1,
          pageSize: 200,
          uni: Date.now() // TODO: Iphone Safari cache
        }
      });
      const data = {
        lastModified: res.headers['last-modified'],
        data: res.data.data,
      };
      commit('update', {key: 'screens', data});
      if ( res.data.meta.prefetchImages) {
        commit('update', {key: 'prefetchImages', data: res.data.meta.prefetchImages});
      }
    } catch (e) {
      console.log(e);
    }
  },

  async getHomeScreen ({ state, dispatch }) {
    let homeScreen = state.object.homeScreen || state.application.homeScreen;
    if (homeScreen) return homeScreen;
    if (!state.screens.data.length) {
      await dispatch('getAllScreens');
    }
    homeScreen = state.screens.data.find(el => el.attributes.ishomescreen === true)
    if (homeScreen) return homeScreen.id;
    else return false;
  }


};

export const mutations = {
  updateScreenById(state, payload) {
    const index = _.findIndex(state.screens.data, screen => screen.id == payload.id);
    if (index != -1) {
      state.screens.data[index].attributes = payload.screen;
    }
  },
  restoreState(state, payload) {
    const keys = Object.keys(payload);
    for (const key of keys) {
      if (state[key]) {
        Object.assign(state[key], payload[key]);
      }
    }
  },
  update(state, payload) {
    state[payload.key] = payload.data;
  },

  setDevmode(state, payload) {
    state.devmode = payload
  },

  setState (state, { key, value }) {
    state[key] = value
  }
};

export const strict = false;
