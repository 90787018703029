import makeGoto from './goto';
import makeScrollTo from './scrollTo';
import makeCreateEvent from './createEvent';
import makeOauthOpenIdReq from './oauthOpenIdReq';
import makeCreatePayment from './createPayment';
import makeUpdateObject from './updateObject';
import makeExecuteOperations from './executeOperations';
import makeSetVariable from './setVariable';
import makeRefreshHashtags from './refreshHashtags';
import makeExitFromApp from './exitFromApp';
import makeSetProperty from './setProperty';
import makeCallActions from './callActions';
import makeExecuteJS from './executeJS';
import makeCreateRecordInTableOfList from './createRecordInTableOfList';
import makeUpdateRecordInTableOfList from './updateRecordInTableOfList';
import makeDeleteRecordInTableOfList from './deleteRecordInTableOfList';
import makeGtagEvent from './gtagEvent';

import { isSuccessConditions } from '../conditions';

import { replaceHashtagsByDependencies } from '../hashtags';

import _ from 'lodash';
// import { getComponentByUUID } from '../screen';

const actions = ctx => {
  const actionsList = {
    goto: makeGoto(ctx),
    scrollTo: makeScrollTo(ctx),
    createEvent: makeCreateEvent(ctx),
    oauthOpenIdReq: makeOauthOpenIdReq(ctx),
    createPayment: makeCreatePayment(ctx),
    updateObject: makeUpdateObject(ctx),
    executeOperations: makeExecuteOperations(ctx),
    setVariable: makeSetVariable(ctx),
    refreshHashtags: makeRefreshHashtags(ctx),
    exitFromApp: makeExitFromApp(ctx),
    setProperty: makeSetProperty(ctx),
    callActions: makeCallActions(ctx),
    reactiveProperty: makeSetProperty(ctx),
    executeJS: makeExecuteJS(ctx),
    createRecordInTableOfList: makeCreateRecordInTableOfList(ctx),
    updateRecordInTableOfList: makeUpdateRecordInTableOfList(ctx),
    deleteRecordInTableOfList: makeDeleteRecordInTableOfList(ctx),
    gtagEvent: makeGtagEvent(ctx),
  }

  return ({
    /**
     *
     * @param {Object} uuidOrActions (string)UUID or (array) actions
     * @param {Array} executeOrder Array of indexes from actions list e.g. [0, 2, 1]
     */
    //execute: async function(actions) {
    execute: async function(data, executeOrder = []) {
      try {
        // let actions = [];
        let { uuid, eventType, actions } = data;
        let name = null
        let component_loop_index = null;
        let component = null

        if (uuid) {

          component = ctx.store.getters['screen/component'](uuid);

          if (component){

            if (component.loopData) {
              const arrLoopDataSource = component.loopData.dataSource.split('.')
              if (arrLoopDataSource[0] === 'List') component_loop_index = component.loopData.index
              else component_loop_index = Number(arrLoopDataSource.pop()) + 1
            }

            name = component.properties.backendname

            window.mbst.bus.$emit('global:componentBeforeActionsExecute', { uuid: component.uuid, name: component.properties.backendname });

            actions = component.actions || [];

            // ** do not refresh hashtags if no actions
            if (!actions.length) {
              window.mbst.bus.$emit('global:componentAfterActionsExecute', { uuid: component.uuid, name: component.properties.backendname })
              return;
            }

            // ** refresh hashtags in current component
            let dependencyFilter = ctx.store.state.screen.dependencies.components.filter(dependency => dependency.uuid == (component.originalUUID || component.uuid))
            if (dependencyFilter && dependencyFilter.length) {

              // ** if current component is looped clone, then replace original-uuid to clone-uuid in dependencyFilter **
              if (component.originalUUID) {
                dependencyFilter = _.cloneDeep(dependencyFilter)
                dependencyFilter.forEach((el, i) => dependencyFilter[i]['uuid'] = component.uuid)
              }
              replaceHashtagsByDependencies(dependencyFilter);
            }

          } else {
            console.error('Component not found for actions execution by UUID: ', uuid);
            return;
          }
        }

        // debugger
        executeOrder = executeOrder.length ? executeOrder : Array.from({ length: actions.length }, (v, k) => k)
        // for (let i=0; i<actions.length; i++) {
        for (let i of executeOrder) {

          let action = actions[i]

          if (action.event && action.event != eventType) continue;

          if (typeof actionsList[action.type] === 'function') {
            let isCond = true;
            if (!_.isEmpty(action.conditions)) {
              isCond = isSuccessConditions(action.conditions);
            }
            if (isCond) {
              // ** execute action **
              const isGoTo = (action.type === 'goto' && (action.options || {}).isOpenNewWindow);
              // const res = await actionsList[action.type](action);
              const res = await actionsList[action.type].call(this, action);

              if (isGoTo) break;

              ctx.app.store._vm.$emit('global:beforeExecuteAction', action);

              if (action.result) {
                await this.execute(res);
                // ctx.app.store._vm.$emit('global:afterExecuteAction', action);
                // ctx.app.store._vm.$emit('global:afterExecuteAction', action);
              }
            }

            // ** hashtags refreshes automaticaly in every action executor
            // ** but problem with loops - after any screen refresh looped components is destroyed and new set is created
            // ** list 'actions' that we have belongs to destroyed and not updated looped component =>
            // ** we need to find new instance of this looped component and teke from it a actual refreshed actions list
            if (name) {
              let component = ctx.store.getters['screen/componentByName'](name, component_loop_index)
              if (component) {
                actions = component.actions
              } else {
                actions = []
                if (component_loop_index) console.error('Execute actions: Component not found in loop')
              }
            }
            // **

          } else {
            throw new Error(`Action "${action.type}" is not defined`);
          }
        }

        if (component) {
          setTimeout(() => window.mbst.bus.$emit('global:componentAfterActionsExecute', { uuid: component.uuid, name: component.properties.backendname }), 1000)
        }

      } catch (e) {
        console.error(e);
      }
    }
  })
}

export default actions;
