import makeActions from '@/utils/actions';
import MakeEventBus from '@/utils/MakeEventBus'

export default (ctx, inject) => {
  const actions = makeActions(ctx);
  inject('actions', actions);
  if (!window.mbst) window.mbst = { bus: null }
  window.mbst.bus = MakeEventBus('global')
  window.mbst.bus.$on('global:actionsExecute', payload => actions.execute(payload));
}
