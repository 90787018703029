import Vue from 'vue'
import { debounce } from 'quasar'

Vue.directive('draggable', {
  bind (el, binding, vnode, oldVnode) {
    el.setAttribute('draggable', true)
    el.addEventListener('dragstart', DragController.start)
    el.addEventListener('dragover', DragController.over)
    el.addEventListener('drop', DragController.drop)
    // el.addEventListener('dragend', DragController.end)
  },
  update (el, binding, vnode, oldVnode) {
    if (typeof binding.value === 'object') {
      el.dragPayload = binding.value
    }
  }
})


const checkDropConditions = (targetElement) => {
  if (DragController.dragTarget === targetElement) {
    return false
  }
  if (DragController.dragTarget.dataset.draggableGroup !== targetElement.dataset.draggableGroup) {
    return false
  }
  if (DragController.dragTarget.tagName === 'LI' && !['UL', 'OL', 'LI'].includes(targetElement.tagName)) {
    return false
  }
  return true
}

const DragController = Object.assign(Vue.observable({ active: false }), {
// const DragController = Object.assign({ active: false, direction: [] }, {
  dragTarget: null,
  callbacks: [],
  direction: [],
  // active: false,
  // dragendCallback: null,

  setCallback (hookName, draggableGroup = null, fooCallback) {
    if (!['start', 'over', 'drop'].includes(hookName)) return false

    draggableGroup = draggableGroup || 'default'
    if (!DragController.callbacks[hookName]) {
      DragController.callbacks[hookName] = {}
    }
    DragController.callbacks[hookName][draggableGroup] = fooCallback
  },

  getCallback (hookName, draggableGroup) {
    if ((DragController.callbacks[hookName] || {})[draggableGroup]) {
      return DragController.callbacks[hookName][draggableGroup]
    }
    return false
  },

  getDirection (element, coords) {
    DragController.direction = [] // { N: false, VC: false, S: false, W: false, HC: false, E: false }
    const box = element.getBoundingClientRect()
    const dh = box.height / 2 // hot vertical zone 50% around the edges
    const dw = box.width / 3 // hot horizontal zone bottom 30% around the edges

    if (coords[1] < dh) DragController.direction.push('N') // north
    else if (coords[1] < box.height - dh) DragController.direction.push('VC') // vertical center
    else DragController.direction.push('S') // south

    if (coords[0] < dw) DragController.direction.push('W') // west
    else if (coords[0] < box.width - dw) DragController.direction.push('HC') // horizontal center
    else DragController.direction.push('E') // east

    return DragController.direction
  },

  async executeCallback (hookName, params) {
    if (!DragController.dragTarget) return
    const cb = DragController.getCallback(hookName, DragController.dragTarget.dataset.draggableGroup)
    // if (hookName === 'over') debugger
    if (cb) cb(...Object.values(params))
  },

  start: (event) => {
    console.log('Drag start')
    DragController.active = true
    DragController.direction = []
    DragController.dragTarget = event.currentTarget.dragPayload || event.target
    DragController.executeCallback('start', { event })
  },

  over: (event) => {
    const dropTarget = event.target.closest('[draggable]')
    if (checkDropConditions(dropTarget)) {
      event.preventDefault && event.preventDefault()
      // const box = this.getOutlineBox(dropTarget)
      // dropTarget.dataset.dropDirection = getDirection(dropTarget, [event.clientX - parseInt(box.left), event.clientY - parseInt(box.top)])
    }
  },

  drop: (event) => {
    // console.log('Drag Drop')
    DragController.active = false
    // const dropTarget = event.currentTarget // .closest('[draggable]')
    // if (!dropTarget) return false
    // const direction = getDirection(event.currentTarget, [event.offsetX, event.offsetY])
    // DragController.executeCallback('drop', { event, direction: DragController.direction, payload: DragController.dragTarget })
    DragController.executeCallback('drop', { event, payload: DragController.dragTarget })
    // return { payload: DragController.dragTarget, direction: DragController.direction }
    DragController.dragTarget = null
  },

  // end: (event) => {
  //   debugger
  // //   // DragController.active = false
  // //   // DragController.direction = []
  // //   // DragController.dragTarget = null
  // //   // if (typeof DragController.dragendCallback === 'function') {
  // //   //   DragController.dragendCallback()
  // //   //   DragController.dragendCallback = null
  // //   // }
  // // },
  //
  // // dragend: (fu) => {
  // //   DragController.dragendCallback = fu
  // }
})

Vue.prototype.$drag = DragController
