export default (e, n, o) => {
  const t = document,
    a = "script",
    i = t.createElement(a),
    r = t.getElementsByTagName(a)[0];
  i.src = e, n && i.addEventListener("load", function (e) {
    n(null, e)
  }, !1), o && i.addEventListener("error", function (e) {
    o(e)
  }, !1), r.parentNode.insertBefore(i, r)
}