<template>
  <q-layout container>
    <q-page-container>
      <q-page class="text-center q-pb-xl">
        <div class="text-h6 bg-white q-pa-sm shadow-1">{{ title }}</div>
        <div class="q-mt-xl">
          <img class="app-icon" :src="appIcon" />
        </div>
        <div class="text-body1">{{ appName }}</div>

        <div class="auth-comp q-mx-auto q-mt-xl">
          <nuxt/>
        </div>
      </q-page>
    </q-page-container>
  </q-layout>

</template>

<script>
  import { QLayout, QPageContainer, QPage } from 'quasar';

  export default {
    components: { QLayout, QPageContainer, QPage },
    computed: {
      application: vm => vm.$store.state.application,
      appName: vm => vm.application.name,
      appIcon: vm => vm.application.iconURL,
      title: vm => vm.application.authTitle,
    }
  }
</script>

<style lang="scss">
  .auth-comp {
    max-width: 235px;
  }
  .app-icon {
    overflow: hidden;
    border-radius: 12px;
    width: 56px;
    height: 56px;
    margin: 0 auto 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(#ccc, .15);

    img {
      max-width: 100%;
      object-fit: contain;
    }
  }
</style>
