import { clearSiteData } from './../clearStorage'

export default ({ app }) => async action =>
  new Promise(async (resolve, reject) => {
    try {
      // ** Drop auth data from object **
      const { appid, objid } = app.context.query
      let authList = await app.$idb.authList.getItem('authList') || {}
      const index = (authList[appid]||[]).findIndex(v => v.objid == objid)

      if (index != -1) authList[appid].splice(index, 1);

      // Setting in IndexedDB
      await app.$idb.authList.setItem('authList', authList);
      // **

      await clearSiteData()
      if (!!window.mobsted) await mobsted.resetWidgetCounters()

      location.reload(true)
      resolve()
    }
    catch (e) {
      console.error(e)
      reject()
    }
  })

